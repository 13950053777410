<template>
  <div class="home-reasons wrap">
    <h2>Price</h2>
    <p class="sub-title">制作料金</p>

        <p>
         制作料金は110万円(税込)～です。制作するホームページの内容によって異なります。基本的には、「制作工数＝料金」となるため、ページ数が多い、機能が多い、図解などの作図が多い、調査・マーケティングに要する工数など、手間がかかるほど料金は比例して高くなります。110万円以上のご予算の確保をお願いしますが、「クライアントのご指定の予算内で提案する」というスタンスのため、予算オーバーになることはございません。他の仕様のホームページよりも価格帯は高くなりますが、ホームページに投じた料金は、必ずホームページの結果から回収することができます。<span class="kome">※制作料とは別途で保守費用が発生します</span>
        </p>
        <!-- <p class="title">制作料金の実績</p>
        <img alt="制作料金の実績pc" src="@/assets/syukyaku-top-g001.png" class="pc-only"/>
        <img alt="制作料金の実績sp" src="@/assets/syukyaku-top-g001sp.png"  class="sp-only"/>
<p class="note">※別途、保守料がかかります</p> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default{
  name: "Home-any-concerns-or-problems",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title{
  font-size: 20px;
  color: var(--main);
  margin: 50px 0 10px 0;
}
.note{
  text-align: right;
}
</style>
