var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"top"},[_c('SystemTop',{staticClass:"mb-150"})],1),_c('div',{staticClass:"background-color2 pt-150 pb-150"},[_c('SystemMerit')],1),_c('div',{staticClass:"pt-150 pb-150 pb-non"},[_c('SystemDevelop')],1),_c('div',{directives:[{name:"prlx",rawName:"v-prlx.mobile.background",value:({
      direction: 'y',
      fromBottom: true,
      speed: 0.1,
    }),expression:"{\n      direction: 'y',\n      fromBottom: true,\n      speed: 0.1,\n    }",modifiers:{"mobile":true,"background":true}}],staticClass:"parallax_content1"}),_c('div',{staticClass:"pt-150 pb-150 background-color"},[_c('SystemOurThoughts')],1),_c('div',{staticClass:"background-color2 pt-150 pb-150 pb-non"},[_c('SystemAdvantages')],1),_c('div',{directives:[{name:"prlx",rawName:"v-prlx.mobile.background",value:({
      direction: 'y',
      fromBottom: true,
      speed: 0.1,
    }),expression:"{\n      direction: 'y',\n      fromBottom: true,\n      speed: 0.1,\n    }",modifiers:{"mobile":true,"background":true}}],staticClass:"parallax_content2"}),_c('div',{staticClass:"pt-150 pb-150"},[_c('SystemPrice')],1),_c('div',{staticClass:"background-color pt-150 pb-150"},[_c('SystemQa')],1),_c('div',{staticClass:"pt-150 pb-150"},[_c('SystemFlow')],1),_c('div',{staticClass:"pt-150 pb-150"},[_c('HomeContact',{attrs:{"consul":false}})],1),_c('div',{staticClass:"background-color pt-150 pb-150"},[_c('HomeServices')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }