<template>
  <div class="home">
    <div class="top mb-150">
      <CompanyTop />
    </div>

   
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CompanyTop from "@/components/Company/Top.vue";

export default {
  name: "Home",
  components: {
    CompanyTop,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.background-color {
  background-color: var(--sub3);
}
.background-color2 {
  background-color: var(--sub);
}
</style>
