<template>
  <div class="home-reasons wrap">
    <h2>Price</h2>
    <p class="sub-title">制作料金</p>

        <p>
         デザイン重視のホームページの制作料金は55万円(税込)～です。制作するホームページの内容によって異なります。基本的には、「制作工数＝料金」となるため、ページ数が多い、撮影をする、デザインにこだわる、図解などの作図が多い、ヒアリングに要する工数など、手間がかかるほど料金は比例して高くなります。また、具体的なデザインイメージをご提示いただくことで、弊社ので創作に要する工数を抑えることができます。弊社は、「クライアントのご指定の予算内で提案する」というスタンスのため、予算をご指定いただければ、その予算を超える提案はしません。<span class="kome">※制作料とは別途で保守費用が発生します</span>
        </p>
        <!-- <p class="title">制作料金の実績</p>
        <img alt="制作料金の実績pc" src="@/assets/syukyaku-top-g001.png" class="pc-only"/>
        <img alt="制作料金の実績sp" src="@/assets/syukyaku-top-g001sp.png"  class="sp-only"/>
<p class="note">※別途、保守料がかかります</p> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default{
  name: "Home-any-concerns-or-problems",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title{
  font-size: 20px;
  color: var(--main);
  margin: 50px 0 10px 0;
}
.note{
  text-align: right;
}
</style>
