<template>
  <div class="design-top">
    <UiTop
      :imgPath="'design/syukyaku-de-001.jpg'"
      :imgPathSp="'design/syukyaku-de-001sp.jpg'"
      :en="'Websites to improve your images'"
      :ja="'ホームページのデザインでイメージアップ'"
      :imgPathMain="'design/syukyaku-de-003.jpg'"
      :img="true"
      :text="'魅力的なデザインのホームページを提案し、ホームページによってイメージアップを目指します。動画を用いたり、動くホームページの仕様（アニメーションデザイン）にしたりと、外観的な魅力を強化します。マーケティングに強みがある弊社だからこそ、ホームページのパフォーマンスを考え、パフォーマンスを犠牲しないデザインの追求・作り込みをすることも可能です。'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTop from "@/components/Ui/Top.vue";
export default {
  name: "design-top",
  components: { UiTop },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
