<template>
  <div class="lowprice-top">
    <UiTop
      :imgPath="'lowprice/syukyaku-tei-001.jpg'"
      :imgPathSp="'lowprice/syukyaku-tei-001sp.jpg'"
      :en="'Developing websites<br>at low cost'"
      :ja="'低コストでホームページを制作'"
      :imgPathMain="'syukyaku-top-006.jpg'"
      :img="true"
      :text="'株式会社グレイトヘルプは、集客や販売強化、求人強化などホームページ制作による目的達成を得意としていますが、極力コストを抑えた低コストでのホームページ制作も対応可能です。「とにかく安く、なるべく安くホームページを作りたい」というニーズにお応えします。11万円(税込)からホームページを制作できます。'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTop from "@/components/Ui/Top.vue";
export default {
  name: "recruit-top",
  components: { UiTop },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
