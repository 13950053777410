<template>
  <div class="dtp-flow wrap">
    <h2>Flow</h2>
    <p class="sub-title">制作の流れ</p>
        <div class="step-cover" v-for="step in steps" :key="step.id">
          <div class="step">
            <p class="no">{{ step.no }}</p>
            <p class="title">{{ step.title }}</p>
          </div>
          <p v-html="step.text" class="text"></p>
        </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiFlowBox from "@/components/Ui/FlowBox.vue";
export default {
  name: "dtp-flow",
  components: {
    // UiFlowBox
    },
  data() {
    return {
  steps: [
        {
          id: 1,
          no: "01",
          title: "お問い合わせ",
          text: "当サイトのお問い合わせフォームから、または、お電話（058-214-9005）でご連絡をお願いします。その際、制作を希望する媒体をお伝えください。",
        },
        {
          id: 2,
          no: "02",
          title: "ヒアリング",
          text: "制作する内容により、異なりますが、弊社から制作する紙媒体についてヒアリングをさせていただきます。",
        },
        {
          id: 3,
          no: "03",
          title: "制作内容の決定と制作料の提示",
          text: "ヒアリング内容をもとに、制作内容と、その制作料金を決定します。",
        },
        {
          id: 4,
          no: "04",
          title: "制作開始",
          text: "弊社にて制作します。",
        },
        {
          id: 5,
          no: "05",
          title: "クライアントにて確認",
          text: "完成品をお見せしますので、内容をご確認ください。修正があれば、対応をします。",
        },
        {
          id: 6,
          no: "06",
          title: "印刷・納品",
          text: "必要な部数を、ご希望の仕様で印刷し、現物を送付することで納品いたします。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  color: white;
  // margin: 50px 0 10px 0;
}
.note {
  text-align: right;
}

</style>
