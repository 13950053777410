<template>
  <div class="home-qa wrap">
    <h2>Frequent Q&A</h2>
    <p class="sub-title">よくある質問</p>
    <UiQaQaBox
      v-for="qaBoxListData in qaBoxList"
      :key="qaBoxListData.id"
      :qestion="qaBoxListData.qestion"
      :answer="qaBoxListData.answer"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiQaQaBox from "@/components/Ui/Qa/QaBox.vue";
export default {
  name: "home-qa",
  components: { UiQaQaBox },
  data() {
    return {
      qaBoxList: [
        {
          id: 1,
          qestion: "制作期間はどれくらいでしょうか？",
          answer:
            "制作する内容に異なり、3ヶ月～4ヶ月です。",
        },
        {
          id: 2,
          qestion: "どんな案件でも対応できますか？",
          answer:
            "ホームページで、商材が持つ本来のポテンシャルを最大限に高めるイメージです。そのため、商材の価値やメリットに競争力がない商品、需要がない商品については、結果を出すことが難しいです。このような商材の場合、打ち合わせ時に、率直にこのような旨をご説明します。結果が出ないことがわかっていて、仕事をお受けすることはございませんので、ご安心してご相談くださいませ。",
        },
        {
          id: 3,
          qestion: "緊急で必要なのですが、納期の短縮は可能でしょうか？",
          answer:
            "弊社の状況および案件により異なりますが、緊急料金にて承ることができる場合がございますので、ご相談ください。",
        },
        {
          id: 4,
          qestion: "遠隔対応で依頼することはできますか？",
          answer:
            "もちろん可能です。全国にクライアントがいます。メール、LINE、チャットワーク、電話、遠隔会議システム（Zoom・スカイプ）などありとあらゆる手段を用意していますので、物理的な距離があっても、業務に差し支えはございません。",
        },
        {
          id: 5,
          qestion: "現在の業者からの切り換えは対応可能でしょうか？",
          answer:
            "もちろん可能です。弊社は、他社からの切り換えによるリニューアルでのホームページ制作が多いです。現在の業者との契約の確認から、現在の業者へお伝えする内容などを弊社がすべて指示します。",
        },
        {
          id: 6,
          qestion: "制作事例を見せていただくことはできますか？",
          answer:
            "弊社の方針・スタンスとして、弊社が手をかけて調査やマーケティングを行って制作したウェブ媒体については、ウェブ上など一般公開された状態で、クライアントの制作物を公開することはしていません。理由は、とても手の込んだ調査とマーケティングによって、ホームページのコンテンツ設計をしているため、弊社が制作したホームページを見ることで、コンテンツの丸パクリをされてしまうからです。実際に、クライアントと同エリアの同業種の企業に、そっくりなコンテンツのホームページを作られてしまうなど、クライアントにとって不利益となってしまったことがあったためです。制作事例については、打ち合わせの際にお見せしています。その場合も、クライアントの許可がある制作事例のみお見せします。このように、貴社の情報についても他社へ公開することはございません。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
