<template>
  <div class="home-types-of-website wrap">
    <h2>Types of websites<br />we can develop</h2>
    <p class="sub-title">制作可能なホームページ</p>
    <div class="box-cover">
      <UiBox4
        v-for="box in boxes"
        :key="box.id"
        :imgPath="box.imgPath"
        :subTitle="box.subTitle"
        :text="box.text"
        :linkPath="box.linkPath"
        :button="true"
        class="box"
      />
      <img
        alt="イメージ"
        src="@/assets/ezgif.com-apng-maker-2.png"
        class="deco"
      />
    </div>
    <UiFreeConsulting :backgroundColor="`white`" :type="`hp`" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBox4 from "@/components/Ui/Box4.vue";
import UiFreeConsulting from "@/components/Ui/FreeConsulting.vue";
export default {
  name: "home-types-of-website",
  components: {
    UiBox4,
    UiFreeConsulting,
  },
  props: { path: String },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
      documentheight: 0,
      boxes: [
        {
          id: 1,
          imgPath: "syukyaku-top-002.jpg",
          subTitle: "集客・販売強化",
          text: "ホームページで集客・販売を強化します。弊社ならではのマーケティング・調査によって、クライアントの事業のポテンシャルをホームページによって最大に引き上げます。",
          linkPath: "/marketing/",
        },
        {
          id: 2,
          imgPath: "syukyaku-top-003.jpg",
          subTitle: "求人サイト・求人強化",
          text: "ウェブを活用して、求人・採用を強化します。求人サイト・求人ページの制作を起点とし、他社の求人媒体への効果的な展開、広告による求人強化を支援します。",
          linkPath: "/recruit/",
        },
        {
          id: 3,
          imgPath: "syukyaku-top-004.jpg",
          subTitle: "デザイン・イメージアップ",
          text: "魅力的なデザインのホームページを提案し、ホームページによってイメージアップを目指します。動画を用いたり、動くホームページの仕様（アニメーションデザイン）にしたりと、外観的な魅力を強化します。",
          linkPath: "/design/",
        },
        {
          id: 4,
          imgPath: "syukyaku-top-005.jpg",
          subTitle: "コーポレートサイト",
          text: "企業として対外的な目的でホームページが必要、しっかりした会社に見えるようにホームページで信頼を演出したい、というニーズにお応えます。",
          linkPath: "/corporate/",
        },
        {
          id: 5,
          imgPath: "syukyaku-top-006.jpg",
          subTitle: "低コストなホームページ制作",
          text: "とにかく低コストでホームページを作りたい、持ちたい、というニーズに合わせて、クライアントと調整のもと、可能な限りコストを抑えたホームページをご提案します。",
          linkPath: "/lowprice/",
        },
        {
          id: 6,
          imgPath: "syukyaku-top-007.jpg",
          subTitle: "業務の自動化・効率化",
          text: "ホームページで業務の効率化を実現します。予約システムの搭載、料金のシミュレーション、店舗の空き状況の表示など、人の手で対応していた業務をホームページ上で対応できるようにします。",
          linkPath: "/efficiency/",
        },
        {
          id: 7,
          imgPath: "syukyaku-top-008.jpg",
          subTitle: "ランディングページ",
          text: "特定の商材の販売を強化するために、1ページ1商材のランディングページを制作します。ランディングページ上のコンテンツはもちろん、離脱防止対策や高機能フォームなどの機能の搭載によって成約率を高める提案もします。",
          linkPath: "/landingpage/",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.box-cover {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  @media screen and(max-width: 767px) {
    flex-direction: column;
  }
  .box {
    width: 45%;
    margin-bottom: 100px;
    @media screen and(max-width: 767px) {
      width: 100%;
      margin-bottom: 50px;
    }
  }
  .deco {
    position: absolute;
    width: 40%;
    bottom: 150px;
    right: 0;
    @media screen and(max-width: 767px) {
      position: static;
      width: 70%;
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
