<template>
  <div class="recruit-top">
    <UiTop
      :imgPath="'corporate/syukyaku-co-001.jpg'"
      :imgPathSp="'corporate/syukyaku-co-001sp.jpg'"
      :en="'Corporate websites<br>that build trust'"
      :ja="'企業としての信頼を演出するコーポレートサイト'"
      :imgPathMain="'syukyaku-top-005.jpg'"
      :img="true"
      :text="'企業として対外的な目的でホームページが必要、しっかりした会社に見えるようにホームページで信頼を演出したい、というニーズにコーポレートサイトの制作でお応えします。コーポレートサイトは「会社の名刺」です。貴社のイメージに合う、しっかりしたコーポレートサイトの構築によって、企業としての信頼を高めます。また、後の事業展開に合わせて柔軟にホームページを更新できるように設計をします。'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTop from "@/components/Ui/Top.vue";
export default {
  name: "recruit-top",
  components: { UiTop },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
