<template>
  <div class="marketing-develop">
    <h2>How to create<br />websites that sell</h2>
    <p class="sub-title">売れるホームページの作り方</p>
    <div class="wrap">
      <p class="min">
        集客や販売を増やせるホームページを作る技術を保有するホームページ制作業者は限られています。その理由は、ただ形としてホームページを作るだけなく、マーケティングのノウハウが必要になるからです。
        ホームページを作るだけであれば、学校や教材などでHTML・CSSというホームページ構築のためのプログラムを学んだり、デザインを学んだりすることで、できるようになります。
        しかし、ホームページで集客や販売を強化するためには、マーケティングを駆使し、データに基づいたホームページのコンテンツ（内容）の設計と構築が必要となります。この技術については、学校では学べず、また、書籍や教材で学んだとしても、最終的にはマーケティングを行うマーケターの経験・スキル・センスによって差が出てしまうものです。
      </p>
      <div class="img-cover min">
        <img
          alt="コーディングとデザインだけでは×"
          src="@/assets/marketing/syukyaku-syukyaku-ira001.png"
          class="hukiashi pc-only"
        />
        <img
          alt="コーディングとデザインだけでは×"
          src="@/assets/marketing/syukyaku-syukyaku-ira001sp.png"
          class="hukiashi sp-only"
        />
        <img
          alt="マーケティングも必要"
          src="@/assets/marketing/syukyaku-syukyaku-ira002.png"
          class="pc"
        />
      </div>
    </div>

    <div class="back-main">
      <div class="wrap center">
        <p>弊社のマーケティング方法</p>
        <img
          alt="弊社のマーケティング方法"
          src="@/assets/common/syukyaku-kyoutu-ira001.png"
          class="man"
        />
      </div>
    </div>
    <div class="wrap">
      <p class="min coment">
        集客・販売強化をホームページで実現するために、弊社は以下のアプローチでマーケティングを実施します。
      </p>
      <div class="box-cover">
        <img
          alt="01"
          src="@/assets/marketing/syukyaku-syukyaku-pa001.png"
          class="maru"
        />
        <div class="box">
          <p class="title">検索ニーズからのアプローチ</p>
          <p class="text">
            GoogleやYahoo!に代表される検索エンジン、YouTube内での検索など、主要な検索システムで、ターゲットとなる人が実際に検索している検索キーワードを拾い出します。これらは、ターゲットが実際に検索しているキーワードであり、ターゲットの知りたいこと、悩みや願望などをストレートに表しています。弊社では、このように実際に検索されているキーワードをウェブ上から収集して、ホームページの設計とコンテンツ作りに活かしていきます。
          </p>
        </div>
      </div>
      <div class="box-cover">
        <img
          alt="01"
          src="@/assets/marketing/syukyaku-syukyaku-pa002.png"
          class="maru"
        />
        <div class="box">
          <p class="title">ターゲットの心理からの<br class="sp" />アプローチ</p>
          <p class="text">
            ターゲットとなる人が具体的に何を知りたいのか、何を求めているのか、その情報を集めていきます。情報の収集場所は主にウェブ上ですが、必要に応じて、書籍や雑誌などのオフラインからも情報を収集します。そして、ターゲットの心理を理解し、それに応えるホームページのコンテンツとライティングをします。
          </p>
        </div>
      </div>
      <div class="box-cover">
        <img
          alt="01"
          src="@/assets/marketing/syukyaku-syukyaku-pa003.png"
          class="maru"
        />
        <div class="box">
          <p class="title">競合コンテンツからの<br class="sp" />アプローチ</p>
          <p class="text">
            同一商圏と商圏外の競合の事業主のホームページのコンテンツをチェックします。そして、制作するホームページに取り入れた要素を洗い出していきます。競合がやっていることで、良いことは確実に参考にし、ミートすることで、競合に負けないホームページに仕上げることができます。特に検索上位のホームページを入念にチェックします。検索上位ということは、検索エンジンに評価されているホームページだからです。
          </p>
        </div>
      </div>
      <div class="box-cover">
        <img
          alt="01"
          src="@/assets/marketing/syukyaku-syukyaku-pa004.png"
          class="maru"
        />
        <div class="box">
          <p class="title">
            弊社の知見による<br class="sp" />他業種からのアプローチ
          </p>
          <p class="text">
            弊社はこれまで多種多様な業種のホームページを制作し、結果を出してきました。その知見が弊社にはあります。弊社が持つ他業種での成功事例、使用したコンテンツの切り口、施策などを制作するホームページに取り入れていきます。
          </p>
        </div>
      </div>

      <p class="mass">
        ここまで説明したアプローチによって、制作するホームページのコンテンツを設計し、かつ、クライアントの事業や商材の価値・強みを明確にし、ポジションやターゲットの設定を行っていきます。ただホームページを作るのではなく、ホームページを作る前のマーケティングがとても重要です。弊社は、ホームページの設計のためのマーケティングに力を入れることで、集客や販売強化を実現しています。
      </p>
      <p class="mass">
        次に重要なことは、事業の発展や成長にホームページがついていけることを見越した設計をすることです。何度もお伝えしていますが、ホームページは作るまでがゴールではありません。作ってからがスタートです。そのため、事業の発展や成長を見越して、ホームページを拡張できる設計にしておくことが重要です。
      </p>
      <p class="mass">
        この視点がないと、事業に変化があるたびにホームページを大きくリニューアルしなければいけなくなってしまったり、つぎはぎのホームページの構成になってしまったり、という問題に直面することになります。弊社では、そうならないように、ホームページの将来的な拡張性と実行を想定する集客方法を考慮した設計をします。さらに、弊社がこれまで蓄積した勝ちパターンに基づいてホームページの設計・構成立案を行っていきます。
      </p>
      <p class="mass">
        そして、継続的にホームページのデータを確認して、コンテンツの更新や改善を行い、ホームページのパフォーマンスを上げていくことです。また、必要に応じて、広告運用やSNS運用など最適な集客方法で集客を強化していきます。<span
          class="kome"
          >※これらのサービスは別途料金となります。
        </span>
      </p>
      <p class="mass">
        このようなアプローチでホームページを制作した結果、以下のような集客・販売数アップの実績があります。具体的なホームページについては、打ち合わせの際にお見せしています。
      </p>

      <img
        alt="マーケティング結果"
        src="@/assets/marketing/syukyaku-syukyaku-002.jpg"
        class="pc-only"
        style="margin-top: 30px"
      />
      <img
        alt="マーケティング結果"
        src="@/assets/marketing/syukyaku-syukyaku-002sp.jpg"
        class="sp-only"
        style="margin-top: 30px"
      />
      <!-- class="min" -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiFreeConsulting from "@/components/Ui/FreeConsulting.vue";
export default {
  name: "marketing-develop",
  components: {
    // UiFreeConsulting,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.min {
  margin-bottom: 0;
}
.img-cover {
  width: 80%;
  display: flex;
  align-items: flex-start;
  margin-top: 50px;
  @media screen and(max-width: 767px) {
    width: 100%;
    flex-direction: column;
  }
  .hukiashi {
    width: 60%;
    @media screen and(max-width: 767px) {
      width: 100%;
    }
  }
  .pc {
    width: 40%;
    margin-top: 50px;
    @media screen and(max-width: 767px) {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
    }
  }
}

.coment {
  margin-bottom: 100px;
  padding-top: 20px;
  @media screen and(max-width: 767px) {
    padding-top: 0;
    margin-bottom: 50px;
  }
}
.box-cover {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;
  @media screen and(max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }

  .box {
    width: 76%;
    @media screen and(max-width: 767px) {
      width: 100%;
    }
    .title {
      color: var(--main);
      margin-bottom: 20px;
      font-size: 26px;
      // font-weight: bold;
      @media screen and(max-width: 767px) {
        font-weight: middle;
        text-align: center;
      }
    }
  }
  img {
    width: 20%;
    @media screen and(max-width: 767px) {
      width: 40%;
      margin-bottom: 20px;
    }
  }
}
//コメント
.kome {
  font-size: 14px;
}
</style>
