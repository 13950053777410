<template>
  <div class="home">
    <div class="top">
      <SystemTop class="mb-150" />
    </div>

    <div class="background-color2 pt-150 pb-150">
      <SystemMerit />
    </div>

    <div class="pt-150 pb-150 pb-non">
      <SystemDevelop />
    </div>

    <!-- <div class="pt-150 pb-150 background-color2">
      <MallOffer />
    </div> -->
    <div
      class="parallax_content1"
      v-prlx.mobile.background="{
        direction: 'y',
        fromBottom: true,
        speed: 0.1,
      }"
    ></div>
    <div class="pt-150 pb-150 background-color">
      <SystemOurThoughts />
    </div>

    <div class="background-color2 pt-150 pb-150 pb-non">
      <SystemAdvantages />
    </div>
    <div
      class="parallax_content2"
      v-prlx.mobile.background="{
        direction: 'y',
        fromBottom: true,
        speed: 0.1,
      }"
    ></div>
    <div class="pt-150 pb-150">
      <SystemPrice />
    </div>

    <div class="background-color pt-150 pb-150">
      <SystemQa />
    </div>

    <div class="pt-150 pb-150">
      <SystemFlow />
    </div>

    <div class="pt-150 pb-150">
      <HomeContact :consul="false" />
    </div>

    <div class="background-color pt-150 pb-150">
      <HomeServices />
    </div>

    <!-- <div class="background-color2 pt-250 pb-250">
      <HomeUseflInfo />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SystemTop from "@/components/System/Top.vue";
import SystemMerit from "@/components/System/Merit.vue";
import SystemDevelop from "@/components/System/Develop.vue";
import SystemOurThoughts from "@/components/System/OurThoughts.vue";
// import MallOffer from "@/components/Mall/Offer.vue";
// import MallSell from "@/components/Mall/Sell.vue";
// import MallReasons from "@/components/Mall/Reasons.vue";
// import HomeOurAchievements from "@/components/Home/OurAchievements.vue";
import SystemAdvantages from "@/components/System/Advantages.vue";
// import MallExpand from "@/components/Mall/Expand.vue";
import SystemPrice from "@/components/System/Price.vue";
import SystemQa from "@/components/System/Qa.vue";
import SystemFlow from "@/components/System/Flow.vue";
import HomeContact from "@/components/Home/Contact.vue";
import HomeServices from "@/components/Home/Services.vue";
// import HomeUseflInfo from "@/components/Home/UseflInfo.vue";

export default {
  name: "System",
  components: {
    SystemTop,
    SystemMerit,
    SystemDevelop,
    SystemOurThoughts,
    // HomeOurAchievements,
    // MallOffer,
    // MallSell,
    // MallReasons,
    SystemAdvantages,
    // MallExpand,
    SystemPrice,
    SystemQa,
    SystemFlow,
    HomeContact,
    HomeServices,
    // HomeUseflInfo,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.background-color {
  background-color: var(--sub3);
}
.background-color2 {
  background-color: var(--sub);
}
.parallax_content1 {
  padding: 250px 0;
  background-image: url(~@/assets/system/syukyaku-sytem-002.jpg);
  background-size: cover;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 150px;
  }
  @media screen and(max-width: 767px) {
    background-size: auto 100vw;
    padding: 120px 0;
  }
}
.parallax_content2 {
  padding: 250px 0;
  background-image: url(~@/assets/system/syukyaku-sytem-003.jpg);
  background-size: cover;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 150px;
  }
  @media screen and(max-width: 767px) {
    background-size: auto 100vw;
    padding: 120px 0;
  }
}
</style>
