<template>
  <div class="home-reasons wrap">
    <h2>Price</h2>
    <p class="sub-title">制作料金</p>

        <p>
         制作料金は、制作するECサイトの内容によって異なります。基本的には、制作工数＝料金となるため、商品カテゴリ数・商品点数が多い、機能が多い、ECビジネスとして仕組みが複雑、調査・マーケティングに要する工数など、手間がかかるほど料金は比例して高くなります。以下、過去の弊社の制作料金の実績です。「クライアントのご指定の予算内で提案する」というスタンスのため、予算オーバーになることはございません。いずれにしても、ECサイトを作るとは、ネット上に自分のお店を持つことです。収益のために、自分のお店に投資をすることは当然のことであり、それは必ず結果となって返ってきます。以下、過去の弊社の制作料金の実績です。
        </p>
        <p class="title">制作料金の実績</p>
        <img alt="制作料金の実績pc" src="@/assets/ec/syukyaku-EC-websit-002.jpg" class="pc-only"/>
        <img alt="制作料金の実績sp" src="@/assets/ec/syukyaku-EC-websit-002sp.jpg"  class="sp-only"/>
<p class="note">※別途、保守料がかかります</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default{
  name: "Home-any-concerns-or-problems",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title{
  font-size: 20px;
  color: var(--main);
  margin: 50px 0 10px 0;
}
.note{
  text-align: right;
  font-size: 15px;
}
</style>
