<template>
  <div class="home-reasons wrap">
    <h2>Price</h2>
    <p class="sub-title">制作料金</p>

        <p>
         88万円(税込)～です。弊社における、結果につながるランディングページの仕様を満たすために必要な予算です。調査・マーケティング、ライティングまで弊社でしっかりと行います。「制作工数＝料金」となるため、クライアントから素材や原稿の提供があるなど、弊社での制作対応の範囲が減る場合は、コストダウンが可能です。弊社は、「クライアントのご指定の予算内で提案する」というスタンスのため、予算オーバーになることはございません。ランディングページは売上に大きく影響を与えるため、投資額が大きくなっても、価値ある商材を販売している限りは、投資金額を必ず回収できます。<span class="kome">※制作料とは別途で保守費用が発生します</span>
        </p>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default{
  name: "Home-any-concerns-or-problems",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title{
  font-size: 20px;
  color: var(--main);
  margin: 50px 0 10px 0;
}
.note{
  text-align: right;
}
</style>
