<template>
  <div class="recruit-top">
    <UiTop
      :imgPath="'mall/syukyaku-EC-mo-001.jpg'"
      :imgPathSp="'mall/syukyaku-EC-mo-001sp.jpg'"
      :en="'Development<br>operation of shopping malls'"
      :ja="'ショッピングモールの制作・運用'"
      :imgPathMain="'syukyaku-top-016.jpg'"
      :img="true"
      :text="'株式会社グレイトヘルプでは、日本の主要ショッピングモールである楽天市場、Amazon、Yahoo!ショッピング、auPAYマーケットへの出店・ショップ構築・集客業務を行っています。これまで、いくつもの業種・ショップを支援してきた経験とノウハウで、販売力の高いショップを構築します。かつ、それを効率的な方法で実現することができます。'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTop from "@/components/Ui/Top.vue";
export default {
  name: "recruit-top",
  components: { UiTop },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
