<template>
  <div class="home-any-concerns-or-problems">
    <h2>Any concerns<br class="sp-only"/> or problems?</h2>
    <p class="sub-title">こんな悩み・お困りごとはありませんか？</p>
    <div class="back-main">
      <div class="wrap center">
        <p>ホームページを制作したいが、どの業者に依頼していいか分からない</p>
      </div>
    </div>
    <div class="back-sub">
      <div class="wrap center">
        <p>実力がある業者に依頼したいが、実力や実績のある業者が見つからない</p>
      </div>
    </div>
    <div class="back-main">
      <div class="wrap center">
        <p>予算に限りはあるが、ホームページで達成したい目的がある</p>
      </div>
    </div>
    <div class="back-sub">
      <div class="wrap center">
        <p>予算に限りはあるが、なるべく良いホームページを作りたい</p>
      </div>
    </div>
    <div class="back-main">
      <div class="wrap center">
        <p>ホームページはあるが、役に立っていないので、リニューアルしたい</p>
      </div>
    </div>
    <div class="back-sub">
      <div class="wrap center">
        <p>現在のホームページ業者は、作った後、何もしてくれない</p>
      </div>
    </div>
    <div class="back-main">
      <div class="wrap center">
        <p>ただホームページを作るだけでなく、SEO/広告/SNSなどの集客もサポートしてほしい</p>
      </div>
    </div>
    <p class="wrap text">こんな悩みやお困りごとをお持ちでしたら、ぜひ引き続き、<br>株式会社グレイトヘルプのホームページ制作サービスについて、ご確認ください！</p>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Home-any-concerns-or-problems",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.back-main,
.back-sub {
  margin-bottom: 20px;
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
        @media screen and (min-width:768px) and ( max-width:1024px){
      padding: 30px 20px;
    }
      @media screen and(max-width: 767px){
  padding: 30px 20px;
  }

    p {
      color: white;
      font-size: 25px;
      @media screen and (max-width: 767px){
        font-size: 20px;
      }
    }
  }
}
.back-main {
  background-color: var(--main);
  margin-top: 0;
}
.back-sub {
  background-color: var(--sub);
}
.text{
  color: var(--sub);
  font-size: 23px;
  text-align: center;
  margin-top: 50px;
}
</style>
