<template>
  <div class="home-flow wrap">
    <h2>Flow</h2>
    <p class="sub-title">制作の流れ</p>
        <div class="step-cover" v-for="step in steps" :key="step.id">
          <div class="step">
            <p class="no">{{ step.no }}</p>
            <p class="title">{{ step.title }}</p>
          </div>
          <p v-html="step.text" class="text"></p>
        </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiFlowBox from "@/components/Ui/FlowBox.vue";
export default {
  name: "Home-flow",
  components: {
    // UiFlowBox
    },
  data() {
    return {
  steps: [
        {
          id: 1,
          no: "01",
          title: "お問い合わせ",
          text: "当サイトのお問い合わせフォームから、または、お電話（058-214-9005）でご連絡をお願いします。その際、「システム開発について」とお伝えください。",
        },
        {
          id: 2,
          no: "02",
          title: "ヒアリング",
          text: "開発するシステムについて、弊社からヒアリングさせていただきます。",
        },
        {
          id: 3,
          no: "03",
          title: "開発内容の決定と開発費の提示",
          text: "ヒアリング内容をもとに、開発内容と、その開発費を決定します。",
        },
        {
          id: 4,
          no: "04",
          title: "契約の締結・着手金のお支払い",
          text: "開発内容と開発費を記載した契約書を送付しますので、署名・押印をし、1通を弊社へ送付し、1通を貴社にて保管ください。また、所定の着手金をお支払いください。",
        },
        {
          id: 5,
          no: "05",
          title: "開発",
          text: "弊社にて開発します。",
        },
        {
          id: 6,
          no: "06",
          title: "クライアントにて確認、利用開始",
          text: "完成品をお見せしますので、内容をご確認ください。修正があれば、対応をします。",
        },
        {
          id: 7,
          no: "07",
          title: "保守・維持管理・運用",
          text: "システムの利用開始後、保守・維持管理を弊社にて行います。改造を行う場合は別途料金にて対応します。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  color: white;
  // margin: 50px 0 10px 0;
}
.note {
  text-align: right;
}

</style>
