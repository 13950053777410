<template>
  <div class="home-reasons wrap">
    <h2>Price</h2>
    <p class="sub-title">制作料金</p>

        <p>
         制作料金は、「制作するホームページの内容」＋「搭載するシステム」によって決まります。基本的には、「制作工数＝料金」となるため、業務効率化のために搭載するシステムが多い、ページ数が多い、図解などの作図が多い、調査・マーケティングに要する工数など、手間がかかるほど料金は比例して高くなります。ご予算の確保をお願いしますが、「クライアントのご指定の予算内で提案する」というスタンスのため、予算オーバーになることはございません。ホームページに投じた料金は、ホームページによる業務効率化の効果から回収することができます。<span class="kome">※制作料とは別途で保守費用が発生します</span>
        </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default{
  name: "Home-any-concerns-or-problems",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title{
  font-size: 20px;
  color: var(--main);
  margin: 50px 0 10px 0;
}
.note{
  text-align: right;
}
</style>
