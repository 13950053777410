<template>
  <div class="home-flow wrap">
    <h2>Flow</h2>
    <p class="sub-title">制作の流れ</p>
        <div class="step-cover" v-for="step in steps" :key="step.id">
          <div class="step">
            <p class="no">{{ step.no }}</p>
            <p class="title">{{ step.title }}</p>
          </div>
          <p v-html="step.text" class="text"></p>
        </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiFlowBox from "@/components/Ui/FlowBox.vue";
export default {
  name: "Home-flow",
  components: {
    // UiFlowBox
    },
  data() {
    return {
  steps: [
        {
          id: 1,
          no: "01",
          title: "お問い合わせ",
          text: "当サイトのお問い合わせフォームから、または、お電話（058-214-9005）でご連絡をお願いします。",
        },
        {
          id: 2,
          no: "02",
          title: "ヒアリング",
          text: "依頼案件によって、その方法や内容は異なりますが、制作するECサイトについて弊社からヒアリングをさせていただきます。",
        },
        {
          id: 3,
          no: "03",
          title: "制作内容の決定と制作料の提示",
          text: "ヒアリング内容をもとに、制作するECサイトの内容と、その制作料金を決定します。",
        },
        {
          id: 4,
          no: "04",
          title: "契約の締結・着手金のお支払い",
          text: "制作内容と制作料金を記載した契約書を送付しますので、署名・押印をし、1通を弊社へ送付し、1通を貴社にて保管ください。また、所定の着手金のお支払いを確認後、制作開始となります。",
        },
        {
          id: 5,
          no: "05",
          title: "契約の締結",
          text: "制作内容と制作料金を記載した契約書を送付しますので、署名・押印をし、1通を弊社へ送付し、1通を貴社にて保管ください。",
        },
        {
          id: 6,
          no: "06",
          title: "制作",
          text: "弊社にてECサイトを制作します。",
        },
        {
          id: 7,
          no: "07",
          title: "クライアントにて確認、公開",
          text: "ECサイトの完成後、ウェブ上に非公開の状態で、お見せします。内容をご確認いただき、必要に応じて修正をし、公開となります。",
        },
        {
          id: 8,
          no: "08",
          title: "保守・維持管理・運用",
          text: "ECサイトの立ち上げ後、保守・維持管理を弊社にて行います。別途料金にて、集客業務を承る場合は、その内容を実施します。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  color: white;
  // margin: 50px 0 10px 0;
}
.note {
  text-align: right;
}

</style>
