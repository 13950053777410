<template>
  <div class="home-reasons wrap">
    <h2>Price</h2>
    <p class="sub-title">制作料金</p>

        <p>
         制作料金は、制作するホームページの内容によって異なります。基本的には、「制作工数＝料金」となるため、ページ数が多い、機能が多い、図解などの作図が多い、調査・マーケティングに要する工数など、手間がかかるほど料金は比例して高くなります。「クライアントのご指定の予算内で提案する」というスタンスのため、予算オーバーになることはございません。以下、過去の弊社の制作料金の実績です。具体的な料金については、ホームページ制作の各案件の詳細ページでご確認をお願いいたします。
        </p>
        <p class="title">制作料金の実績</p>
        <img alt="制作料金の実績pc" src="@/assets/syukyaku-top-g001.png" class="pc-only"/>
        <img alt="制作料金の実績sp" src="@/assets/syukyaku-top-g001sp.png"  class="sp-only"/>
<p class="note kome">※別途、保守料がかかります</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default{
  name: "Home-any-concerns-or-problems",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title{
  font-size: 20px;
  color: var(--main);
  margin: 50px 0 10px 0;
}
.note{
  text-align: right;
}
</style>
