<template>
  <div class="home-services wrap">
    <h2>Our services</h2>
    <p class="sub-title">サービス一覧</p>
    <img alt="旗" src="@/assets/syukyaku-top-ira008.png" class="hata" />

    <div class="back-main">
      <p>制作系サービス</p>
    </div>
    <div class="box-cover">
      <UiBox4
        v-for="box in boxes"
        :key="box.id"
        :imgPath="box.imgPath"
        :subTitle="box.subTitle"
        :text="box.text"
        :linkPath="box.linkPath"
        class="box"
        :button="true"
      />
    </div>

    <div class="back-main">
      <p>集客・運用系サービス</p>
    </div>
    <div class="box-cover">
      <UiBox4
        v-for="box in boxes2"
        :key="box.id"
        :imgPath="box.imgPath"
        :subTitle="box.subTitle"
        :text="box.text"
        :linkPath="box.linkPath"
        class="box"
        :button="false"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBox4 from "@/components/Ui/Box4.vue";
// import UiFreeConsulting from "@/components/Ui/FreeConsulting.vue";
export default {
  name: "home-services",
  components: {
    UiBox4,
    // UiFreeConsulting,
  },
  props: { path: String },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
      documentheight: 0,
      boxes: [
        {
          id: 1,
          imgPath: "syukyaku-top-014.jpg",
          subTitle: "ホームページ制作",
          text: "ホームページを通して実現したい目的をお伝えください。独自のマーケティングを武器に、クライアントの目的を達成するホームページを提案・制作します。",
          linkPath: "/",
        },
        {
          id: 2,
          imgPath: "syukyaku-top-015.jpg",
          subTitle: "ECサイト制作",
          text: "売上が立つECサイト（ネットショップ）を、マーケティングスキームから提案し、制作します。汎用システムを利用して、コストを抑えてECサイトを制作することも可能です。",
          linkPath: "/ec/",
        },
        {
          id: 3,
          imgPath: "syukyaku-top-016.jpg",
          subTitle: "ショッピングモール制作",
          text: "楽天市場、Amazon、Yahoo!ショッピング、auPAYマーケットの制作を行います。これまでいくつもの業種・ショップを対応してきた経験とノウハウで、販売力の高いショップを構築します。",
          linkPath: "/mall/",
        },
        {
          id: 4,
          imgPath: "syukyaku-top-017.jpg",
          subTitle: "システム開発",
          text: "人の手で行っている業務を、パソコン・タブレット・スマートフォンで行えるようにし、自動化・効率化します。人の採用が難しい現代だからこそ、システム化のメリットは大きいです。",
          linkPath: "/system/",
        },
        {
          id: 5,
          imgPath: "syukyaku-top-024.jpg",
          subTitle: "DTP（紙媒体のデザインと印刷）",
          text: "名刺・チラシ・ショップカード・封筒など紙媒体のデザインと印刷を行います。デザイン性を重視する対応も、コストを重視する対応も可能です。",
          linkPath: "/dtp/",
        },
      ],
      boxes2: [
        {
          id: 1,
          imgPath: "syukyaku-top-018.jpg",
          subTitle: "SEO対策＋解析",
          text: "SEO対策の強化・改善として、ホームページの制作後、ホームページのアクセスや検索順位などのデータを取得し、そのデータに基づいてホームページの改善案を提案・実行します。",
          linkPath: "/",
        },
        {
          id: 2,
          imgPath: "syukyaku-top-011.jpg",
          subTitle: "広告運用",
          text: "Google広告、Yahoo!広告、Facebook広告の出稿によって、集客・販売を強化します。弊社のホームページ制作とセットでの広告運用で、パフォーマンスが最大化します。",
          linkPath: "/",
        },
        {
          id: 3,
          imgPath: "syukyaku-top-012.jpg",
          subTitle: "SNS運用",
          text: "Instagram、Facebook、TwitterなどのSNSの運用を代行、または、アドバイスします。フォロワー数を増やすインフルエンサーを目指す方法と、着実に見込み客を集めることを目指す方法があります。",
          linkPath: "/",
        },
        {
          id: 4,
          imgPath: "syukyaku-top-013.jpg",
          subTitle: "Googleビジネスプロフィール",
          text: "GoogleMap、Google検索対策となるGoogleビジネスプロフィールのコンテンツ制作、運用、口コミ獲得など全面的にサポート可能です。",
          linkPath: "/",
        },
        // {
        //   id: 5,
        //   imgPath: "syukyaku-top-022.jpg",
        //   subTitle: "コンサルティング",
        //   text: "名顧問契約を結び、ウェブ・マーケティングを主とした事業改善を実施します。設定した目標の達成に向けて、継続的な施策を行っていきます。",
        //   linkPath: "/",
        // },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.wrap {
  position: relative;
}
.hata {
  position: absolute;
  top: -2%;
  right: -13%;
  width: 30%;
  @media screen and (min-width:768px) and ( max-width:1100px){
    right: 5%;
    top: -36px;
    width: 20%;
  }
  @media screen and(max-width: 767px) {
    right: 20px;
    width: 47%;
    top: -1.7%;
  }
}
.box-cover {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  @media screen and(max-width: 767px) {
    flex-direction: column;
  }
  .box {
    width: 45%;
    margin-bottom: 80px;
    @media screen and(max-width: 767px) {
      width: 100%;
    }
  }
}

.back-main {
  background-color: var(--main);
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 0;
  p {
    color: white;
    font-size: 28px;
    @media screen and(max-width: 767px) {
      font-size: 23px;
    }
  }
}
</style>
