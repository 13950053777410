<template>
  <div class="home-reasons wrap">
    <h2>Price</h2>
    <p class="sub-title">制作料金</p>

        <p>
        ホームページ制作料金は、基本的に「制作工数＝料金」となります。そのため、ここで説明したアプローチによって、コストカットをすることができます。ホームページで実現したい目的を意識しつつ、コストカットのアプローチをすることで、最低11万円(税込)からホームページの制作ができます。<span class="kome">※制作料とは別途で保守費用が発生します</span>
        </p>
        <!-- <p class="title">制作料金の実績</p>
        <img alt="制作料金の実績pc" src="@/assets/syukyaku-top-g001.png" class="pc-only"/>
        <img alt="制作料金の実績sp" src="@/assets/syukyaku-top-g001sp.png"  class="sp-only"/>
<p class="note">※別途、保守料がかかります</p> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default{
  name: "Home-any-concerns-or-problems",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title{
  font-size: 20px;
  color: var(--main);
  margin: 50px 0 10px 0;
}
.note{
  text-align: right;
}
</style>
