<template>
  <div class="marketing-offer">
    <h2>Operation services<br />we can offer</h2>
    <p class="sub-title">対応可能な運用系サービス</p>
    <div class="wrap">
      <div class="back-main">
        <div class="wrap center">
          <p>ホームページの解析と改善によるSEO対策</p>
        </div>
      </div>
      <div class="back-sub">
        <div class="wrap center">
          <p>インターネット広告運用による集客強化</p>
        </div>
      </div>
      <div class="back-main">
        <div class="wrap center">
          <p>SNS運用による集客強化</p>
        </div>
      </div>
      <div class="back-sub">
        <div class="wrap center">
          <p>Googleビジネスプロフィールのコンテンツ制作と運用サポート</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "marketing-develop",
  components: {
    // UiFreeConsulting,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
h2,
p {
  color: white;
}
.back-main,
.back-sub {
  margin-bottom: 20px;
  margin-top: 0;
  @media screen and(max-width: 767px){
    margin-bottom: 5px;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    @media screen and(max-width: 767px) {
      padding: 30px 20px!important;
    }

    p {
      color: white;
      font-size: 25px;
      @media screen and(max-width: 767px){
        font-size: 23px;
      }
    }
  }
}
.wrap{
  padding: 0;
}
.back-main {
  background-color: var(--main);
   margin-top: 10px;
  @media screen and(max-width: 767px){
    margin-top: 5px;
  }
  }
.back-sub {
  background-color: white;
  p {
    color: var(--main) !important;
  }
}
.space {
  margin-top: 100px;
}
</style>
