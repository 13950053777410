<template>
  <div class="marketing-top">
    <UiTop
      :imgPath="'marketing/syukyaku-syukyaku-001.jpg'"
      :imgPathSp="'marketing/syukyaku-syukyaku-001sp.jpg'"
      :en="'Sales enhancement with websites'"
      :ja="'ホームページで集客・販売強化'"
      :imgPathMain="'syukyaku-top-002.jpg'"
      :img="true"
      :text="'株式会社グレイトヘルプは、マーケティングを駆使することで、集客や販売の強化をホームページで実現することができます。2012年の創業以来、完全成果報酬のインターネットでの販売で結果を出し続けてきたそのノウハウで、ホームページを制作します。ホームページへの集客方法も想定し、競合に負けないホームページの制作をし、さらに継続的なコンテンツ改善やインターネット広告の運用などによって、集客・販売を強化します。'"
    />




  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTop from "@/components/Ui/Top.vue";
export default {
  name: "marketing-top",
  components: { UiTop },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
