<template>
  <div class="recruit-top">
    <UiTop
      :imgPath="'system/syukyaku-sytem-001.jpg'"
      :imgPathSp="'system/syukyaku-sytem-001sp.jpg'"
      :en="'System development'"
      :ja="'中小企業向けシステム開発'"
      :imgPathMain="'system/syukyaku-sytem-004.jpg'"
      :img="false"
      :text="'株式会社グレイトヘルプは、業務のシステム化による効率化や自動化を実現しています。人の手で行っている業務を、パソコン・タブレット・スマートフォンで行い、自動化・効率化します。中小企業でも、なるべく大きな投資を必要とせず、システムを導入できることを目指し、中小企業にとって依頼しやすいシステム開発をスタンスとしています。'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTop from "@/components/Ui/Top.vue";
export default {
  name: "recruit-top",
  components: { UiTop },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
