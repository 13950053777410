<template>
  <div class="top">
    <div class="cover">
      <div class="filter">
        <video loop autoplay muted playsinline>
          <source src="@/assets/gh-syuukyaku-top.mp4" />
          <!-- <source src="@/assets/header1280-720px.mp4" /> -->
          このブラウザではサポートされていません。
        </video>
      </div>

      <div class="container">
        <div class="top">
          <img
            alt="グレイトヘルプトップ pc"
            src="@/assets/syukyaku-top-Z01.png"
            class="pc-only"
          />
          <img
            alt="グレイトヘルプトップ sp"
            src="@/assets/syukyaku-top-Z01sp.png"
            class="sp-only"
          />
        </div>
        <div class="logo">
          <img
            alt="グレイトヘルプトップ pc"
            src="@/assets/syukyaku-top-Z02.png"
            class="pc-only"
          />
          <img
            alt="グレイトヘルプトップ sp"
            src="@/assets/syukyaku-top-Z02sp.png"
            class="sp-only"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "top",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  // mounted: function () {
  //   let player = this.$refs.player;
  //   let container = this.$refs.container;

  //   player.addEventListener("timeupdate", function () {
  //     if (player.currentTime > 23) {
  //       container.style.display = "none";
  //       container.classList.remove("animation-start");
  //     }
  //   });

  //   player.addEventListener("ended", function () {
  //     console.log("ended");
  //     container.style.display = "";
  //     container.classList.add("animation-start");
  //     player.play();
  //   });
  // },
};
</script>

<style lang="scss" scoped>
.cover {
  // background-image: url(~@/assets/top-chira.png);
  // background-color: rgb(183, 190, 216); //動画に黒のフィルターかける
  background-color: #232323; //動画に黒のフィルターかける
  display: inline-block; //上記とセット
  position: relative;
  //ここからスマホ全画面用
  margin: 0 auto;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: -70px;
  vertical-align: bottom;
  //   @media screen and (max-width: 820px){
  // padding-bottom: 200px;
  //   }

  .filter {
    opacity: 0.8;
    display: block;
    // background-image: url(~@/assets/top-chira.png);

    //ここからスマホ全画面用
    position: relative !important;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background: no-repeat center center/cover;
  }

  video {
    min-width: 100%;
    min-height: 100vh;
    position: absolute;
  }

  /*動画よりも画面が縦に長くなるとき用*/
  @media (max-aspect-ratio: 16/9) {
    video {
      height: 100%;
      left: 60%;
      transform: translateX(-60%);
    }
  }

  .container {
    position: absolute;
    width: 30%;
    top: 30%;
    left: 3%;
          @media screen and (min-width:768px) and ( max-width:1024px){
         width: 90%;
      top: 25%;
      left: 10vw;
      height: 100%;
      }
    @media screen and(max-width: 767px) {
      width: 90%;
      top: 0;
      height: 100%;
    }
    .top,
    .logo {
      position: absolute;
      top: 0;
      left: 0;
      animation-name: fadeUpAnime;
      animation-fill-mode: forwards;
      opacity: 0;
      width: 100%;
      @media screen and(max-width: 767px){
        top: 10%;
      }
    }

    .top {
      animation-duration: 2s;
      animation-delay: 3s;

      @media screen and(max-width: 767px) {
        width: 60%;
        left: 22%;
        padding-top: 60%;
      }
    }
    .logo {
      animation-duration: 5s;
      @media screen and(max-width: 767px) {
        width: 7%;
        top: 10%;
      left: 92%;
      }
    }
    @keyframes fadeUpAnime {
      from {
        opacity: 0;
        transform: translateY(100px);
      }

      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.sp-check-cover {
  display: none;
  @media screen and (max-width: 820px) {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }
  .check {
    @media screen and (max-width: 820px) {
      display: initial;
      margin: 20px 0;
      width: 80%;
    }
  }
}

// @keyframes rotate-y{
//   0%{transform:rotateY(0deg);}
//   100%{transform:rotateY(360deg);}
// }
// @-webkit-keyframes rotate-x{
//   0%{-webkit-transform:rotateY(0deg);}
//   100%{-webkit-transform:rotateY(360deg);}
// }
</style>
