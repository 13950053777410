<template>
  <div class="marketing-develop">
    <h2>How to create<br>corporate websites</h2>
    <p class="sub-title">コーポレートサイトの作り方</p>
    <div class="wrap">
      <p class="min">
        コーポレートサイトとは、企業ホームページのことです。事業内容や会社情報などを掲載したホームページです。
      </p>
      <p class="min">
       製造業や建設業の下請け業者がその代表ですが、ウェブへの依存度が低い事業内容の企業の場合、ホームページがなくても、事業は回ります。しかし、事業をする中で、新しく取引をする際、銀行から融資を受ける際など、ホームページを確認されることがあります。こんなとき、「ホームページがない」というのでは、さすがにこの時代においては信頼を得ることができません。
      </p>
      <p class="min">
        このような状況で必要となるのが、コーポレートサイトです。コーポレートサイトは、言い換えるなら、「会社の名刺」です。しっかりした内容・外観のコーポレートサイトがあると、信頼につながります。
      </p>
      <p class="min">
        実際に弊社でコーポレートサイトを作ったクライアントは、以下のような状況となり、コーポレイトサイトが必要となりました。
      </p>
    </div>

    <div class="back-main">
      <div class="wrap center">
        <p>コーポレートサイトが<br class="tb-sp-only">必要となるきっかけ</p>
        <img
          alt="求人専用ページや求人詳細ページのメリット"
          src="@/assets/common/syukyaku-kyoutu-ira001.png"
          class="man"
        />
      </div>
    </div>
    <div class="wrap sp-cover">
      <ul class="check-list">
        <li>新しい取引先との取引の際に、「ホームページを見せてほしい」と言われた</li>
        <li>求人の応募者に「ホームページがないからどんな会社か不安だった」と言われた</li>
        <li>新しいサービスの申込時に、「ホームページURL」の入力欄があり、入力必須だったから申し込みできなかった</li>
        <li>勝手に自社の情報が掲載される媒体があるから、ホームページは不要と思っていたら、望まない情報、間違った情報を掲載されてしまった</li>
        <li>銀行に融資を受けにいったら、「企業の実態が分からないからホームページを見せてほしい」と言われた</li>
        <li>同じ地域に似た名前の事務所ができてしまい、自分のお客様が、そのホームページを間違って見てしまっている</li>
        <li>「この時代にホームページがないって珍しいけど、なんか怪しい事業でもしているの？」と言われた</li>
      </ul>

      <p class="mass">
        ITが進化しきったこの時代において、事業をしていく上で、会社の事業内容や情報を記したホームページは必要です。ホームページで売上アップや集客強化などを狙わないとしても、ホームページはもはや必要な時代です。ないことが普通ではない時代になっています。
      </p>
      <p class="mass">
        また、複数の事業を持つ企業は、それぞれの事業の集客用のホームページを持ち、すべての事業を事業内容として掲載したコーポレートサイトを持つ、というホームページの構成が最適です。複数の事業を持つホールディングス的な立派な企業であることを印象づけられます。
      </p>
        <img
          alt="図"
          src="@/assets/corporate/syukyaku-co-001.png"
          class="figure"
        />

    </div>

    <div class="back-main">
      <div class="wrap center">
        <p>コーポレートサイトの制作で<br class="tb-sp-only">大切なこと</p>
        <img
          alt="コーポレートサイトの制作で大切なこと"
          src="@/assets/common/syukyaku-kyoutu-ira002.png"
          class="man woman"
        />
      </div>
    </div>
    <div class="wrap sp-cover">

      <ul class="check-list">
        <li>企業のイメージに合うデザイン・外観であること</li>
        <li>今後の事業の展開に合わせて、ホームページを変更しやすい構成で作ること</li>
        <li>形式上必要という場合は、コストを落とすこと</li>
      </ul>

      <p class="mass">
       信頼演出のためのホームページという位置づけのため、弊社は、上記の方針で、コーポレートサイトの提案と制作をしています。特に、今後の事業の展開を意識し、ホームページを変更しやすい、拡張しやすいように作ることは重要です。この視点でホームページの設計をすることで、後の更新費用を抑えることができます。
      </p>
      <p class="mass">
        また、形式上、コーポレートサイトが必要という目的の場合は、制作コストを落とすというアプローチも非常に重要です。なるべくコストを落とす制作方法もご提案させていただきます。
      </p>
  
    </div>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiFreeConsulting from "@/components/Ui/FreeConsulting.vue";
export default {
  name: "marketing-develop",
  components: {
    // UiFreeConsulting,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.wrap{
  @media screen and(max-width: 767px){
  padding-top: 25px;
  padding-bottom: 25px;
  }
}

.coment {
  margin-bottom: 100px;
}

.sp-cover{
        @media screen and(max-width: 767px){
      padding-top: 0;
      padding-bottom: 0;
      }
}
.check-list {
  list-style: none;
          @media screen and(max-width: 767px){
      padding-left: 0;
      margin-top: 0;
      }
  li {
    background: url(~@/assets/common/syukyaku-kyoutu-pa001.png) no-repeat 0 0;
    background-size: auto 42px;
    color: var(--main);
    font-size: 26px;
    padding: 0px 0 40px 80px;
    @media screen and(max-width: 767px) {
      font-size: 21px;
      background-size: auto 31px;
      padding: 0 0 27px 44px;
    }
  }
}
.figure{
  width: 100%;
  margin-top: 50px;
}
</style>
