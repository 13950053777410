<template>
  <div class="marketing-develop">
    <h2>Types of EC websites<br />we can develop</h2>
    <p class="sub-title">対応可能なシステム開発案件</p>
    <div class="wrap">
      <p class="min">
        社内で従業員が手作業で行っていることを、システムによって効率化や自動化を実現したり、紙面で管理している情報をデータ管理にすることでペーパーレス化を実現したり、エクセルで計算・集計しているものを、管理画面での入力形式にすることで、データの編集権限の管理や作業をミスをなくしたり、ということを実現できます。「この作業って、従業員ではなく、コンピューターでできないかな」や「この作業がすごく手間でミスが起こりやすいから、コンピューターで正確にできないかな」など、合理化したい業務の対象があれば、ご相談をいただきたいです。
      </p>
    </div>
    <div class="wrap back-sub">
      <div class="cover">
        <div class="min" v-for="item in itemList" :key="item.id">
          <img
            :alt="item.title"
            :src="require(`@/assets/common/${item.ImgPath}`)"
          />
          <div class="right">
            <p class="title" v-html="item.title"></p>
            <p class="text" v-html="item.text"></p>
          </div>
        </div>
      </div>
    </div>

    <!-- <UiFreeConsulting :backgroundColor="`white`" :type="`ec`" class="space" /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiFreeConsulting from "@/components/Ui/FreeConsulting.vue";
export default {
  name: "marketing-develop",
  components: {
    // UiFreeConsulting,
  },
  data() {
    return {
      itemList: [
        {
          id: 1,
          ImgPath: "syukyaku-kyoutu-maru001.png",
          title: "顧客管理システム",
          text: "顧客の会社名、連絡先、顧客に対する活動内容、過去の受注内容など、顧客の情報を顧客ごとに管理するシステムを構築しています。必要なときに、すぐに顧客の情報を検索して、確認することができます。",
        },
        {
          id: 2,
          ImgPath: "syukyaku-kyoutu-maru002.png",
          title: "受注・発注システム",
          text: "商品を発注する企業と受注する企業をシステムで連携し、それに付随する、受注処理、伝票発行、在庫管理などを一括して行うシステムを構築しています。注文を受けた情報をすべてシステム上で管理することができます。",
        },
        {
          id: 3,
          ImgPath: "syukyaku-kyoutu-maru003.png",
          title: "在庫管理システム",
          text: "商品在庫の入庫数と出庫数を入力し、在庫数を管理するシステムを構築しています。リアルタイムで商品の在庫を確認でき、また、棚卸しの手間も大幅に削減することができます。",
        },
        {
          id: 4,
          ImgPath: "syukyaku-kyoutu-maru004.png",
          title: "伝票発行システム<br class=sp-only>（納品書の発行など）",
          text: "商品の納品書や請求書などの伝票を発行するシステムを構築しています。たとえば、ネットショップで受注した商品の情報から、納品書に情報を自動で転記したり、CSV形式で入力したデータから自動で所定のフォーマットの伝票へ情報を書き込んだり、ということができます。",
        },
        {
          id: 5,
          ImgPath: "syukyaku-kyoutu-maru005.png",
          title: "売上管理システム",
          text: "売上を、日別・週別・月別・年別・クライアント別・案件別などなどさまざまな観点から売上データを集計し、閲覧したり、比較したりすることができます。事業の分析に役立つシステムです。",
        },
        {
          id: 6,
          ImgPath: "syukyaku-kyoutu-maru006.png",
          title: "勤怠・シフト管理システム",
          text: "従業員の勤怠を管理したり、シフトの構築と管理をしたりするシステムを構築しています。汎用のシフト管理システムではできない、貴社の業務に合わせた管理までシステム化することができます。",
        },
        {
          id: 7,
          ImgPath: "syukyaku-kyoutu-maru007.png",
          title:
            "従業員向け給与計算システム、<br>個人事業主向け報酬管理システム",
          text: "従業員の給与計算や、個人事業主を相手にした報酬管理システムの構築をしています。従業員の給与計算システムにおいては、貴社特有の名目の給与の管理をすることができます。また、近年、「面貸し・業務委託」の形態を採る美容院に代表されるよう、個人事業主を対象とした報酬計算システムでは、貴社特有の売上(報酬)と経費(天引き)を効率的に集計することができます。",
        },
        {
          id: 8,
          ImgPath: "syukyaku-kyoutu-maru008.png",
          title: "受付業務の<br class=sp-only>タッチパネルシステム",
          text: "美容室やエステサロン、マッサージ店などで、来店されたお客様に対して、現状のヒアリングなど、スタッフが毎回同じ質問をしているような業務をしているのであれば、タブレットによるタッチパネル化で無人対応することができるようになります。お客様が回答した情報をデータとして保管することも可能です。",
        },
        {
          id: 9,
          ImgPath: "syukyaku-kyoutu-maru008.png",
          title: "予約管理システム",
          text: "予約制の事業形態の事業主様に対して、インターネットからの予約システムを構築しています。お客様がインターネットから24時間予約でき、従業員がその予約をシステムにて管理するシステムです。スマートフォンが普及した今、電話での予約を好む人が減り、深夜でも、電話ができない状況でも、スマートフォンから予約ができるネット予約の導入は、電話応対業務の削減による業務効率アップのみならず、予約数増の売上アップにつもつながります。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.back-main,
.back-sub {
  margin-bottom: 20px;
  @media screen and(max-width: 767px) {
    margin-bottom: 0;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;

    p {
      color: white;
      font-size: 25px;
    }
  }
}
.back-main {
  background-color: var(--main);
}
.back-sub {
  background-color: var(--sub3);
}
.space {
  margin-top: 100px;
}
.cover {
  padding: 100px 0;
  position: relative;
  margin-top: 50px;
}
.min {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 50px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 0;
    align-items: center;
    // padding-right: 20px;
    // padding-left: 20px;
  }

  img {
    width: 100px;
    margin-right: 20px;
  }
  .right {
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    .title {
      font-size: 30px;
      color: var(--main);
      width: calc(95% - 100px);
      text-align: left;
      margin-bottom: 20px;
      margin-top: 10px;
      @media screen and (max-width: 767px) {
        width: 100%;
        font-size: 23px;
        text-align: center;
        margin-top: 20px;
      }
    }
    .text {
      width: 100%;
      @media screen and (max-width: 767px) {
        padding-bottom: 50px;
      }
    }
  }
}
</style>
