import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'

import Home from '../views/Home.vue'
import Marketing from '../views/Marketing.vue'
import Recruit from '../views/Recruit.vue'
import Design from '../views/Design.vue'
import Corporate from '../views/Corporate.vue'
import Lowprice from '../views/Lowprice.vue'
import Efficiency from '../views/Efficiency.vue'
import Landingpage from '../views/Landingpage.vue'
import Ec from '../views/Ec.vue'
import Mall from '../views/Mall.vue'
import System from '../views/System.vue'
import Dtp from '../views/Dtp.vue'
import Company from '../views/Company.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '岐阜のウェブ・ホームページ制作「株式会社グレイトヘルプ」',
      description: '岐阜のホームページ制作なら株式会社グレイトヘルプ。ホームページで、集客・販売強化、求人強化、デザインでイメージアップ、お値打ち価格での制作など、クライアントの目的を達成するホームページ制作を得意としています。制作後のSEO対策・広告運用・SNS運用も支援可能です。'
    }
  },
  {
    path: '/marketing/',
    name: 'marketing',
    component: Marketing,
    meta: {
      title: '集客・販売を強化するホームページ制作「(株)グレイトヘルプ」',
      description: '岐阜市にある株式会社グレイトヘルプは、マーケティングを駆使することで、集客や販売の強化をホームページで実現することができます。2012年の創業以来、完全成果報酬のインターネットでの販売で結果を出し続けてきたそのノウハウで、ホームページへの集客方法も想定し、競合に負けないホームページを制作します。'
    }
  },
  {
    path: '/recruit/',
    name: 'recruit',
    component: Recruit,
    meta: {
      title: '求人専用サイト・求人専用ページの制作「(株)グレイトヘルプ」',
      description: '岐阜のホームページ制作会社グレイトヘルプは、ウェブで求人・採用を強化します。求人専用サイト・求人ページを制作をし、さらに、第三者求人媒体への効果的な展開、広告によって求職者を集客して求人強化を支援します。ウェブ制作×マーケティングに強みがある弊社ならではの最新の求人強化ノウハウを提案・実行します。'
    }
  },
  {
    path: '/design/',
    name: 'design',
    component: Design,
    meta: {
      title: 'デザイン・イメージアップのホームページ制作「(株)グレイトヘルプ」',
      description: '魅力的なデザインのホームページを提案・制作し、ホームページによってイメージアップを目指します。動画を用いたり、動くホームページの仕様（アニメーションデザイン）にしたりと、外観的な魅力を強化します。制作実績として匠味平野様のホームページを公開しています。'
    }
  },
  {
    path: '/corporate/',
    name: 'corporate',
    component: Corporate,
    meta: {
      title: 'コーポレートサイトの制作なら「株式会社グレイトヘルプ」',
      description: '企業として対外的な目的でホームページが必要、しっかりした会社に見えるようにホームページで信頼を演出したい、というニーズにコーポレートサイトの制作でお応えます。貴社のイメージに合う、しっかりしたコーポレートサイトの構築によって、企業としての信頼を高めます。'
    }
  },
  {
    path: '/lowprice/',
    name: 'lowprice',
    component: Lowprice,
    meta: {
      title: '安い・低コストでホームページ制作なら(株)グレイトヘルプ',
      description: '岐阜市にある(株)グレイトヘルプは、11万円(税込)からホームページを制作できます。「とにかく安く、なるべく安くホームページを作りたい」というニーズにお応えします。集客や販売強化、求人強化などホームページ制作による目的達成を得意としていますが、極力コストを抑えた低コストでのホームページ制作も対応可能です。'
    }
  },
  {
    path: '/efficiency/',
    name: 'efficiency',
    component: Efficiency,
    meta: {
      title: 'システムを搭載して業務を効率化するホームページ制作',
      description: 'ホームページにシステムを搭載することで、業務の効率化を実現します。人の手で行っている業務をウェブ化することで、効率化・自動化が可能です。具体的には、電話対応の削減、予約の空き状況の表示と更新、料金の見積もりなど、人が行っている業務を、ホームページで閲覧者自身でしてもらえるようにします。'
    }
  },
  {
    path: '/landingpage/',
    name: 'landingpage',
    component: Landingpage,
    meta: {
      title: '売れるランディングページ制作「株式会社グレイトヘルプ」',
      description: '岐阜でランディングページ制作なら株式会社グレイトヘルプ。ランディングページ上のコンテンツはもちろん、離脱防止対策や高機能フォームなどの機能の搭載によって成約率を高める提案もします。これまでアフィリエイト・販売代行で結果を出し続けてきた弊社だからこそできるランディングページ制作のノウハウがあります。'
    }
  },
  {
    path: '/ec/',
    name: 'ec',
    component: Ec,
    meta: {
      title: '岐阜のネットショップ・ECサイト制作なら(株)グレイトヘルプ',
      description: '岐阜市にある株式会社グレイトヘルプは、マーケティング・集客・販売に強いECサイト・ネットショップの制作と集客を得意としています。フルスクラッチでの構築や汎用システム（ec-force、shopify、EC-CUBE、BASE）を用いた構築など、クライアントの案件・目的に合わせた構築方法を提案します。'
    }
  },
  {
    path: '/mall/',
    name: 'mall',
    component: Mall,
    meta: {
      title: 'ショッピングモール制作（楽天・Amazon・Yahoo!・au）',
      description: '岐阜のウェブ制作会社「株式会社グレイトヘルプ」は、日本の主要ショッピングモールである楽天市場、Amazon、Yahoo!ショッピング、auPAYマーケットへの出店・ショップ構築・集客業務を行っています。いくつもの業種・ショップを支援してきた経験とノウハウで、販売力の高いショップを構築します。'
    }
  },
  {
    path: '/system/',
    name: 'system',
    component: System,
    meta: {
      title: '中小企業向けシステム開発なら岐阜の(株)グレイトヘルプ',
      description: '中小企業向けのシステム開発なら岐阜市の株式会社グレイトヘルプ。人の手で行っている業務を、パソコン・タブレット・スマートフォンで行い、自動化・効率化します。中小企業でも、なるべく大きな投資を必要とせず、システムを導入できることを目指し、中小企業にとって依頼しやすいシステム開発をスタンスとしています。'
    }
  },
  {
    path: '/dtp/',
    name: 'dtp',
    component: Dtp,
    meta: {
      title: 'DTP（名刺・チラシなど紙媒体のデザイン制作と印刷）',
      description: '株式会社グレイトヘルプでは、DTP（紙媒体のデザイン・印刷）について、名刺・チラシ・パンフレット・封筒・POP・ポスター・シールなど幅広い案件に対応可能です。各種印刷についてもご依頼可能です。'
    }
  },
  {
    path: '/company/',
    name: 'company',
    component: Company,
    meta: {
      title: '会社情報-株式会社グレイトヘルプ',
      description: '岐阜のホームページ・ECサイト制作「株式会社グレイトヘルプ」の会社情報です。'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");
  if (to.path.slice(-1) === "/") {
    next();
  } else {
    next(to.path + "/");
  }
  });

export default router
