<template>
  <div class="home-qa wrap">
    <h2>Frequent Q&A</h2>
    <p class="sub-title">よくある質問</p>
    <UiQaQaBox
      v-for="qaBoxListData in qaBoxList"
      :key="qaBoxListData.id"
      :qestion="qaBoxListData.qestion"
      :answer="qaBoxListData.answer"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiQaQaBox from "@/components/Ui/Qa/QaBox.vue";
export default {
  name: "home-qa",
  components: { UiQaQaBox },
  data() {
    return {
      qaBoxList: [
      {
          id: 1,
          qestion: "制作期間はどれくらいでしょうか？",
          answer:
            "制作する内容に異なり、2ヶ月～3ヶ月です。",
        },
        {
          id: 2,
          qestion: "制作した実績のあるジャンルや案件を教えてください",
          answer:
            "化粧品、サプリメント、健康食品、食品、ペットフード、学習用教材、家具、美容サロン、インターンシップ、セミナー、など多種多様な案件を対応してきました。いかなる商材であっても、ランディングページ制作のノウハウの本質は同じです。ある程度、一般的なジャンルであれば、対応可能です。",
        },
        {
          id: 3,
          qestion: "緊急で必要なのですが、納期の短縮は可能でしょうか？",
          answer:
            "弊社の状況および案件により異なりますが、緊急料金にて承ることができる場合がございますので、ご相談ください。",
        },
        {
          id: 4,
          qestion: "遠隔対応で依頼することはできますか？",
          answer:
            "もちろん可能です。ランディングページは、全国からリモートでの依頼が特に多い案件となっています。メール、LINE、チャットワーク、電話、遠隔会議システム（Zoom・スカイプ）などありとあらゆる手段を用意していますので、物理的な距離があっても、業務に差し支えはございません。",
        },
        {
          id: 5,
          qestion: "制作事例を見せていただくことはできますか？",
          answer:
            "弊社の方針・スタンスとして、弊社が手をかけて調査やマーケティングを行って制作したウェブ媒体については、ウェブ上など一般公開された状態で、クライアントの制作物を公開することはしていません。理由は、とても手の込んだ調査とマーケティングによって、ホームページのコンテンツ設計をしているため、弊社が制作したホームページを見ることで、コンテンツの丸パクリをされてしまうからです。実際に、クライアントと同エリアの同業種の企業に、そっくりなコンテンツのホームページを作られてしまうなど、クライアントにとって不利益となってしまったことがあったためです。制作事例については、打ち合わせの際にお見せしています。その場合も、クライアントの許可がある制作事例のみお見せします。このように、貴社の情報についても他社へ公開することはございません。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
