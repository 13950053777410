<template>
  <div class="recruit-top">
    <UiTop
      :imgPath="'dtp/syukyaku-dtp-001.jpg'"
      :imgPathSp="'dtp/syukyaku-dtp-001sp.jpg'"
      :en="'DeskTop Publishing'"
      :ja="'DTP（紙媒体のデザイン・印刷）'"
      :imgPathMain="'dtp/syukyaku-dtp-002.jpg'"
      :img="false"
      :text="'株式会社グレイトヘルプでは、DTP（紙媒体のデザイン・印刷）について、幅広い案件に対応可能です。会社として、ウェブのマーケティングとコンテンツ制作に強みがあり、その強みを紙媒体でも活かすことができます。クライアントからの希望と、商品・ターゲットの両方を考慮したデザインを提案します。'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTop from "@/components/Ui/Top.vue";
export default {
  name: "recruit-top",
  components: { UiTop },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
