<template>
  <div class="recruit-top">
    <UiTop
      :imgPath="'efficiency/syukyaku-gyoumu-001.jpg'"
      :imgPathSp="'efficiency/syukyaku-gyoumu-001sp.jpg'"
      :en="'Improving work efficiency<br>with a website'"
      :ja="'ホームページで業務効率化'"
      :imgPathMain="'efficiency/syukyaku-gyoumu-002.jpg'"
      :img="true"
      :text="'ホームページは集客や認知拡大だけでなく、業務の効率化も実現できます。人の手で行っている業務をウェブ化することで、効率化・自動化が可能です。具体的には、電話対応の削減、予約の空き状況の表示と更新、料金の見積もりなど、人が行っている業務を、ホームページ上で閲覧者自身でしてもらえるようにします。'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTop from "@/components/Ui/Top.vue";
export default {
  name: "recruit-top",
  components: { UiTop },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
