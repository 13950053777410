<template>
  <div class="mall-qa wrap">
    <h2>Frequent Q&A</h2>
    <p class="sub-title">よくある質問</p>
    <UiQaQaBox
      v-for="qaBoxListData in qaBoxList"
      :key="qaBoxListData.id"
      :qestion="qaBoxListData.qestion"
      :answer="qaBoxListData.answer"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiQaQaBox from "@/components/Ui/Qa/QaBox.vue";
export default {
  name: "mall-qa",
  components: { UiQaQaBox },
  data() {
    return {
      qaBoxList: [
        {
          id: 1,
          qestion: "制作期間はどれくらいでしょうか？",
          answer:
            "開発する案件によって全く異なります。そのため、依頼内容をしっかりと確認するまでは、算定できません。",
        },
        {
          id: 2,
          qestion: "システム化について全く知識がありませんが、依頼できますか？",
          answer:
            "可能です。弊社のシステム担当にて、現在の業務についてヒアリングし、どのようにシステムで自動化・効率化するか、提案させていただきます。手間がかかっていて改善したい業務やお悩みのことについて、率直にお伝えください。",
        },
        {
          id: 3,
          qestion: "緊急で必要なのですが、納期の短縮は可能でしょうか？",
          answer:
            "弊社の状況および案件により異なりますが、緊急料金にて承ることができる場合がございますので、ご相談ください。",
        },
        {
          id: 4,
          qestion: "遠隔対応で依頼することはできますか？",
          answer:
            "システム化する業務の対象について、お客様が説明・言語化できるか、できないかによって対応が異なります。<br><br>お客様がシステム化の対象について説明・言語化できる場合は、遠隔対応が可能です。メール、LINE、チャットワーク、電話、遠隔会議システム（Zoom・スカイプ）などありとあらゆる手段を用意していますので、物理的な距離があっても、業務に差し支えはございません。<br><br>お客様がシステム化の対象について説明・言語化できない場合は、弊社が現地で業務を確認する必要があります。弊社スタッフの出張費が料金に加算されますが、業務としては対応可能です。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
