<template>
  <div class="dtp-create">
    <div class="title wrap">
      <h2>Types of DTP<br />we can create</h2>
      <p class="sub-title">対応可能な案件</p>
      <img alt="DTP" src="@/assets/dtp/syukyaku-dtp-ira001.png" class="note" />
    </div>
    <div class="wrap">
      <div class="min box-cover">
        <div class="box">
          <p class="blue-back">名刺</p>
          <p class="text">
            シンプルな名刺から、作り込んだ名刺、仕事につながる名刺など各種名刺のデザインを行っています。箔押し加工や、押し出し加工などの特殊な加工を施した名刺についても、デザイン・印刷が可能です
          </p>
        </div>
        <div class="box">
          <p class="blue-back">冊子型名刺・折りたたみ型名刺</p>
          <p class="text">
            表面・裏面のみの名刺ではなく、4ページ式の折りたたみ型の名刺や、8ページや12ページの冊子型の名刺の制作も可能です。情報を多く掲載した名刺になるため、名刺交換から仕事の獲得や、自社のアピールに役立ってくれます。
          </p>
        </div>
        <div class="box">
          <p class="blue-back">チラシ・パンフレット</p>
          <p class="text">
            仕事の獲得を目指すチラシ、企業や製品を紹介するパンフレットの制作も可能です。Aサイズの表面・裏面から、冊子タイプまで対応可能です。
          </p>
        </div>
        <div class="box">
          <p class="blue-back">封筒</p>
          <p class="text">各種サイズの封筒のデザイン・印刷も対応可能です。</p>
        </div>
        <div class="box">
          <p class="blue-back">POP</p>
          <p class="text">店内掲示用のPOPのデザイン・印刷も対応可能です。</p>
        </div>
        <div class="box">
          <p class="blue-back">ポスター</p>
          <p class="text">
            B1サイズやB2サイズのポスターのデザイン・印刷も対応可能です。
          </p>
        </div>
        <div class="box">
          <p class="blue-back">シール</p>
          <p class="text">
            商品や箱に貼るシールなど、シール類のデザイン・印刷も対応可能です。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiFreeConsulting from "@/components/Ui/FreeConsulting.vue";
export default {
  name: "dtp-create",
  components: {
    // UiFreeConsulting,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  .note {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      width: 150px;
      right: 5px;
    }
    @media screen and(max-width: 767px) {
      display: none;
    }
  }
}
.box-cover {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 0;
  }
  .box {
    width: 47%;
    margin-bottom: 30px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 50px;
    }
    .blue-back {
      background-color: var(--main);
      color: white;
      font-size: 25px;
      padding: 10px 0;
      text-align: center;
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 23px;
      }
      @media screen and(max-width: 767px) {
        font-size: 20px;
      }
    }
    .text {
      margin-top: 20px;
      @media screen and(max-width: 767px) {
        margin-top: 10px;
      }
    }
  }
}
.min {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
  }
}
</style>
