<template>
  <div id="app">
    <Header :path="path" />
    <router-view class="body-top" :path="path" />
    <ToTopButton :path="path" />
    <Footer :path="path" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "reset-css";
import Header from "@/components/Layout/Header.vue";
import ToTopButton from "@/components/Ui/ToTopButton.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "App",
  data() {
    return {
      // path: String,
      scrollTimer: null,
    };
  },
  components: {
    Header,
    Footer,
    ToTopButton,
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scrollTimer === null) {
        // console.log(document.body.clientHeight);
        this.documentheight = document.body.clientHeight;
        this.scrollTimer = setTimeout(
          function () {
            // this.scrollY = window.scrollY;
            this.$store.dispatch("setScrollY", window.scrollY);
            clearTimeout(this.scrollTimer);
            this.scrollTimer = null;
            console.log("store scrollY: " + this.$store.getters.scrollY);
          }.bind(this),
          200
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");
#app {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  color: #3e3a39;
  line-height: 2.5em;

  @media screen and (max-width: 767px) {
    line-height: 1.6em;
  }
  text-align:justify;
}

:root {
  --main: rgb(23,28,97);
  --sub: rgb(138,140,175);
  --sub2: rgb(116,113,112);
  --sub3: rgb(224,225,234);
  --black: rgb(62,58,57);
}

html * {
  box-sizing: border-box !important;
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}
.wrap {
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1150px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
  // width: 1150px;
  margin: 0 auto;
  line-height: 2.0em;
  font-size: 18px;

  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.body-top {
  margin-top: 70px;
  @media screen and (max-width: 767px) {
    margin-top: 60px !important;
  }
}

.pc-only {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.tb-only {
  display: none;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    display: block !important;
  }
}
.tb-sp-only {
  display: none;
  @media screen and (max-width: 1024px) {
    display: block !important;
  }
}
.sp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: block !important;
  }
}
h1,
.sub-title {
  font-size: 18px;
  color: var(--main);
  text-align: center;
  margin-bottom: 80px;
  @media screen and (max-width: 767px){
    line-height: 1.5em;
    margin-bottom: 30px;
  }

}
h2 {
  text-align: center;
  font-size: 65px;
  line-height: 1.1em;
  color: var(--sub2);
  font-weight: 500;
  margin-bottom: 20px;
  // font-family: "YuMincho", /* Mac用 */ "Yu Mincho", /* Windows用 */ serif;
  // font-weight: bold;
  @media screen and (max-width: 767px) {
    font-size: 30px;
    line-height: 1.3em;
  }
}
h3 {
  font-size: 35px;
  line-height: 1.6em;
  margin-bottom: 30px;
  text-align: center;
  @media screen and (max-width: 767px) {
    font-size: 23px;
  }
}
ul {
  padding-left: 20px;
  list-style-type: disc;
  list-style-position: outside;
  margin: 30px 0;
}
.link-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  a {
    border: 1px solid var(--main);
    padding: 4px 50px 5px 50px;//微調整
    text-decoration: none;
    color: var(--main);
    &:hover {
      cursor: pointer;
      background-color: var(--main);
      color: white;
      transition: 1s;
    }
  }
}
.min {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
    @media screen and(max-width: 767px) {
    width: 100%;
  }
}

//共通スペースレンジャー
.pt-150 {
  padding-top: 150px;
  @media screen and (max-width: 767px) {
    padding-top: 70px;
  }
}
.pt-200 {
  padding-top: 200px;
  @media screen and (max-width: 767px) {
    padding-top: 100px;
  }
}
.pt-250 {
  padding-top: 250px;
  @media screen and (max-width: 767px) {
    padding-top: 120px;
  }
}
.pb-150 {
  padding-bottom: 150px;
  @media screen and (max-width: 767px) {
    padding-bottom: 70px;
  }
}
.pb-200 {
  padding-bottom: 200px;
  @media screen and (max-width: 767px) {
    padding-bottom: 100px;
  }
}
.pb-250 {
  padding-bottom: 250px;
  @media screen and (max-width: 767px) {
    padding-bottom: 120px;
  }
}
.mt-150 {
  margin-top: 150px;
  @media screen and (max-width: 767px) {
    margin-top: 80px;
  }
}
.mt-200 {
  margin-top: 200px;
  @media screen and (max-width: 767px) {
    margin-top: 100px;
  }
}
.mt-250 {
  margin-top: 250px;
  @media screen and (max-width: 767px) {
    margin-top: 120px;
  }
}
.mb-150 {
  margin-bottom: 150px;
  @media screen and (max-width: 767px) {
    margin-bottom: 70px;
  }
}
.mb-200 {
  margin-bottom: 200px;
  @media screen and (max-width: 767px) {
    margin-bottom: 100px;
  }
}
.mb-250 {
  margin-bottom: 250px;
  @media screen and (max-width: 767px) {
    margin-bottom: 120px;
  }
}
//スマホのときにスペースなしにする
.pb-non{
  @media screen and(max-width: 1024px){
    padding-bottom: 0;
  }
}
.pt-non{
  @media screen and(max-width: 1024px){
    padding-top: 0;
  }
}
.mass {
  margin-bottom: 30px;
}

.back-main {
  margin-top: 100px;
  margin-bottom: 100px;
  background-color: var(--main);
  position: relative;
        @media screen and(max-width: 767px){
      margin: 70px 0 30px 0;
      }
   .center,.between {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
        @media screen and (min-width:768px) and ( max-width:1024px){
            padding: 30px 0;
      justify-content: flex-start;
      padding-left: 20px;
    }
    @media screen and(max-width: 767px) {
      padding: 30px 0;
      justify-content: flex-start;
      padding-left: 20px;
    }

    p {
      color: white;
      font-size: 36px;
            @media screen and (min-width:768px) and ( max-width:1024px){
        line-height: 1.2;
            }
      @media screen and(max-width: 767px) {
        font-size: 23px;
      }
    }
    .man {
      width: 150px;
      position: absolute;
      bottom: 0;
      right: calc(calc(100vw - 1100px) * 0.5);
      @media screen and (min-width:768px) and ( max-width:1024px){
        right: 20px;
        width: 130px; 
      }
      @media screen and(max-width: 767px) {
        right: 5px;
        width: 80px;
      }
    }
    .woman {
      width: 100px;
      @media screen and(max-width: 767px) {
        width: 70px;
      }
    }
        .woman2{
      width: 130px;
            @media screen and(max-width: 767px) {
        width: 100px;
      }
    }
  }
    .between{
    align-items: center;
    justify-content: flex-start;
    margin: 30px 50px;
     @media screen and(max-width: 767px) {
    justify-content: center;
    padding: 0;
    margin: 0;
    text-align: center;
    }
    .man{
      width: 23%;
            position: absolute;
      bottom: 0;
      right: 50px;
       @media screen and(max-width: 767px) {
         display: none;
    }
  }
}
}
//flow
.step-cover {
  margin-bottom: 50px;
  position: relative;
  .step {
    background-color: var(--main);
    color: white;
    display: flex;
    padding: 20px 0;
        @media screen and (max-width: 767px) {
    padding: 15px 0;
    }
    .no {
      width: 100px;
      font-size: 27px;
      text-align: center;
      border-right: white 1px solid;
      @media screen and (max-width: 767px) {
        max-width: 75px;
        vertical-align: middle;
      }
    }
    .title {
      padding-left: 50px;
      font-size: 22px;
      @media screen and (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
      }
    }
  }
  .text {
    margin-top: 10px;
  }
}
.kome {
  font-size: 14px;
}
</style>
