<template>
  <div class="home-qa wrap">
    <h2>Frequent Q&A</h2>
    <p class="sub-title">よくある質問</p>
    <UiQaQaBox
      v-for="qaBoxListData in qaBoxList"
      :key="qaBoxListData.id"
      :qestion="qaBoxListData.qestion"
      :answer="qaBoxListData.answer"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiQaQaBox from "@/components/Ui/Qa/QaBox.vue";
export default {
  name: "home-qa",
  components: { UiQaQaBox },
  data() {
    return {
      qaBoxList: [
        {
          id: 1,
          qestion: "制作期間はどれくらいでしょうか？",
          answer:
            "制作する内容に異なり、1ヶ月～2ヶ月です。",
        },
        {
          id: 2,
          qestion: "安いホームページで結果は出るのでしょうか？",
          answer:
            "コスト＝工数なので、予算を投じたホームページの方がパフォーマンスは高くなります。やみくもにコストを削って安く作ったホームページでは結果は出ません。これで結果が出るならば、世の中、困る人はいないはずです。大切なことは、ホームページによって達成したい目的を意識して、削れるコストを削ることです。このアプローチについては、お仕事の打ち合わせ時に提案させていだきます。",
        },
        {
          id: 3,
          qestion: "緊急で必要なのですが、納期の短縮は可能でしょうか？",
          answer:
            "弊社の状況および案件により異なりますが、緊急料金にて承ることができる場合がございますので、ご相談ください。",
        },
        {
          id: 4,
          qestion: "遠隔対応で依頼することはできますか？",
          answer:
            "もちろん可能です。全国にクライアントがいます。メール、LINE、チャットワーク、電話、遠隔会議システム（Zoom・スカイプ）などありとあらゆる手段を用意していますので、物理的な距離があっても、業務に差し支えはございません。",
        },
        {
          id: 5,
          qestion: "現在の業者からの切り換えは対応可能でしょうか？",
          answer:
            "もちろん可能です。弊社は、他社からの切り換えによるリニューアルでのホームページ制作が多いです。現在の業者との契約の確認から、現在の業者へお伝えする内容などを弊社がすべて指示します。",
        },
        // {
        //   id: 6,
        //   qestion: "制作事例を見せていただくことはできますか？",
        //   answer:
        //     "弊社の方針・スタンスとして、弊社が手をかけて調査やマーケティングを行って制作したウェブ媒体については、ウェブ上など一般公開された状態で、クライアントの制作物を公開することはしていません。理由は、弊社はマーケティングや集客、サイト制作の実績が豊富あるため、サイトを公開したことで模倣され、クライアントにとって不利益となってしまったことがあるためです。制作事例については、打ち合わせの際にお見せしています。その場合も、クライアントの許可がある制作事例のみお見せします。このように、貴社の情報についても他社へ公開することはございません。",
        // },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
