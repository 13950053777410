<template>
  <div class="recruit-top">
    <UiTop
      :imgPath="'recruit/syukyaku-kyu-001.jpg'"
      :imgPathSp="'recruit/syukyaku-kyu-001sp.jpg'"
      :en="'Enhancing staff recruitment with websites'"
      :ja="'求人専用サイト・求人ページで求人を強化'"
      :imgPathMain="'recruit/syukyaku-kyu-002.jpg'"
      :img="true"
      :text="'ウェブを活用して、求人・採用を強化します。求人専用サイト・求人ページの制作を軸とし、第三者求人媒体への効果的な展開、広告による求人強化を支援します。「有料求人媒体に求人を出す」という古典的で、誰でもできる、効果が期待できない手法ではありません。弊社ならではの最新の求人強化ノウハウを提案・実行します。'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTop from "@/components/Ui/Top.vue";
export default {
  name: "recruit-top",
  components: { UiTop },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
