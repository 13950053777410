<template>
  <div class="marketing-develop">
    <h2>How to develop websites<br />at low cost</h2>
    <p class="sub-title">低コストでホームページを作る方法</p>
    <div class="wrap">
      <p class="min">
        「ホームページ制作のコスト」＝「制作に要する工数」です。そのため、弊社が制作に要する工数を下げることができれば、コストを下げることができます。具体的なアプローチとして、以下があります。
      </p>
    </div>

    <div class="back-main">
      <div class="wrap center">
        <p>ホームページの仕様による<br class="tb-sp-only" />コストカット</p>
        <img
          alt="ホームページの仕様によるコストカット"
          src="@/assets/common/syukyaku-kyoutu-ira001.png"
          class="man"
        />
      </div>
    </div>
    <div class="wrap">
      <p class="mass">
        どのようなホームページを作るか、という点でコストの削減ができます。具体的に、以下の内容でコストの削減が可能です。
      </p>
      <ul>
        <li>掲載する情報やページ数を限定する</li>
        <li>シンプルなデザイン、シンプルな作りにする</li>
        <li>搭載するシステムや機能を限定する</li>
      </ul>
    </div>

    <div class="back-main">
      <div class="wrap center">
        <p>
          クライアント側での<br class="tb-sp-only" />業務対応によるコストカット
        </p>
        <img
          alt="クライアント側での業務対応によるコストカット"
          src="@/assets/common/syukyaku-kyoutu-ira002.png"
          class="man woman"
        />
      </div>
    </div>
    <div class="wrap">
      <p class="mass">
        クライアント側で業務にご協力いただく範囲を増やすことでコストの削減ができます。具体的に、以下の内容でコストの削減が可能です。
      </p>

      <ul>
        <li>ホームページに掲載する文章をお客様にてご提供していただく</li>
        <li>ホームページに掲載する画像をお客様にてご提供していただく</li>
        <li>制作内容について具体的な指示があり、弊社側での検討が発生しない</li>
        <li>
          具体的なデザインイメージを挙げていただくことで、弊社での創作工数を削減する
        </li>
        <li>
          遠隔会議対応や弊社事務所への訪問によって、弊社従業員の移動が発生しない
        </li>
      </ul>
    </div>
    <div class="back-main">
      <div class="wrap center">
        <p>
          弊社側での業務対応の<br class="tb-sp-only" />効率化によるコストカット
        </p>
        <img
          alt="弊社側での業務対応の効率化によるコストカット"
          src="@/assets/common/syukyaku-kyoutu-ira003.png"
          class="man"
        />
      </div>
    </div>
    <div class="wrap">
      <p class="mass">
        弊社側での業務対応を効率化することでもコストの削減ができます。具体的に、以下の内容でコストの削減が可能です。
      </p>

      <ul>
        <li>
          弊社が保有するホームページのテンプレートを使用することで工数を抑える
        </li>
        <li>
          クライアントが保有する既存の媒体のデザインを流用することで工数を抑える
        </li>
      </ul>
    </div>

    <div class="back-main">
      <div class="wrap center">
        <p>
          クライアントの<br class="tb-sp-only" />スタンスによるコストカット
        </p>
        <img
          alt="弊社側での業務対応の効率化によるコストカット"
          src="@/assets/common/syukyaku-kyoutu-ira004.png"
          class="man woman3"
        />
      </div>
    </div>
    <div class="wrap">
      <p class="mass">
        クライアントの業務の依頼スタンスも、コストに影響があります。具体的に、以下のスタンスの場合、コストの削減が可能です。
      </p>
      <ul>
        <li>弊社におまかせというスタンスで、過度な検討や修正が発生しない</li>
        <li>依頼後の変更や修正などの手戻りがない</li>
        <li>細かなことにこだわらない</li>
      </ul>

      <p class="mass">
        ここまでの説明のとおり、いろいろなアプローチから、ホームページ制作のコストを抑えることはできます。具体的には打ち合わせの際に、貴社保有の資源などを確認して、ご提案させていただきます。
      </p>
      <p>
        そして、ここが重要なのですが、予算を抑えつつ、達成したい目的を意識して、その達成を目指したホームページを制作することです。率直にご予算と目的をお伝えください。貴社が保有する資源や、依頼内容を精査して、どうすれば、その予算内で良いご提案ができるかを考えます。もちろん、予算＝工数のため、低予算で目的達成ができるという甘い世界ではありませんが、ベストな方法を提案させていただきます。
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiFreeConsulting from "@/components/Ui/FreeConsulting.vue";
export default {
  name: "marketing-develop",
  components: {
    // UiFreeConsulting,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.min {
  margin-bottom: 0;
}

.coment {
  margin-bottom: 100px;
}
.back-main {
  margin-top: 80px;
  @media screen and (max-width: 767px) {
  margin-top: 0;
  }
}
.wrap {
  @media screen and(max-width: 767px) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
.woman {
  width: 100px;
  @media screen and(max-width: 767px) {
    width: 60px;
  }
}

ul {
  list-style: none;
  @media screen and(max-width: 767px) {
    padding-left: 0;
    padding-bottom: 0;
  }
  li {
    background: url(~@/assets/common/syukyaku-kyoutu-pa001.png) no-repeat 0 0;
    background-size: auto 42px;
    color: var(--main);
    font-size: 26px;
    padding: 0px 0 40px 80px;
    @media screen and(max-width: 767px) {
      font-size: 21px;
      background-size: auto 31px;
      padding: 0 0 27px 44px;
    }
  }
}
.woman3{
  @media screen and(max-width: 767px){
    width: 84px;
  }
}
</style>
