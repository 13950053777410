<template>
  <div class="layout-footer">
    <!-- <div class="tokuteisho" v-show="path !== `/tokuteisho/`">
      <router-link to="/tokuteisho/">特定商取引法に基づく表示</router-link>
    </div> -->
    <div class="footer" :class="{ 'footer-sp-bottom': path !== `/contact/` }">
      <div class="fotter-inner">
        <div class="logo">
          <img
            alt="グレイトヘルプロゴ"
            src="@/assets/GH-syoukai-top-logo002.png"
          />
        </div>
        <div class="copyright">
          <p>Copyright © GreatHelp All Rights Reserved.</p>
        </div>
      </div>

      <div class="sp-contact-overlay">
        <div class="contact-item">
          <a class="tel-box" href="tel:0582149005">
            <img alt="Line" src="@/assets/tel-sp.png" />
            <span>電話での問い合わせ</span>
          </a>

          <router-link to="/" v-scroll-to="`#mailto`" class="mail-box">
            <img alt="Line" src="@/assets/mail-sp.png" />
            <span>メールでの問い合わせ</span>
          </router-link>

          <!-- <div class="line-box" v-scroll-to="'#contact-line'">
            <img alt="Line" src="@/assets/Line.png" />
            <span>LINE</span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LayoutFooter",
  components: {},
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: var(--sub2);
}
.st1 {
  fill: none;
}
.tokuteisho {
  text-align: center;
  margin-bottom: 5px;
  a {
    text-decoration: none;
    color: black;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.footer {
  .fotter-inner {
    background-color: var(--main);
    // height: 150px;
    .logo {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      img {
        width: 200px;
        margin: 80px 0 0;
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          margin: 50px 0 20px;
          width: 120px;
        }
        @media screen and (max-width: 767px) {
          width: 70px;
          margin: 50px 0 0;
        }
      }
    }
  }

  .copyright {
    font-size: 13px;
    color: white;
    text-align: right;
    padding-bottom: 20px;
    margin-right: 100px;
    @media screen and (max-width: 767px) {
      margin-right: 0;
      margin-top: 15px;
      text-align: center;
    }
  }
  .sp-contact-overlay {
    display: none;
    @media screen and (max-width: 767px) {
      display: initial;
      position: fixed;
      bottom: 0;
      width: 100%;
      // height: 65px;
      background-color: rgba(255, 255, 255, 0.9);
      z-index: 999;
      .contact-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
        a{
          text-decoration: none;
        }
      .tel-box,
      .line-box,
      .mail-box {
        display: flex;
        position: relative;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        padding: 10px 0 5px;
        border: 1px solid gray;
        span {
          color: var(--main);
          margin-top: 3px;
          font-size: 13px;
        }
        img {
          width: 28px;
          margin-right: 10px;
        }
      }
      .tel-box {
        // ::before {
        //   position: absolute;
        //   content: "電話する";
        //   display: block;
        //   padding: 5px 0;
        //   text-align: center;
        //   border-radius: 2.6em;
        //   background-color: var(--main);
        //   position: absolute;
        //   font-size: 2.5vw;
        //   color: #fff;
        //   line-height: 1.1;
        //   top: -3vw;
        //   left: 5%;
        //   right: 5%;
        //   z-index: 200;
        // }
      }
      .mail-box {
        // ::before {
        //   position: absolute;
        //   content: "メールで問い合わせる";
        //   display: block;
        //   padding: 5px 0;
        //   text-align: center;
        //   border-radius: 2.6em;
        //   background-color: var(--main);
        //   position: absolute;
        //   font-size: 2.5vw;
        //   color: #fff;
        //   line-height: 1.1;
        //   top: -3vw;
        //   left: 5%;
        //   right: 5%;
        //   z-index: 200;
        // }
      }
      .line-box {
        ::before {
          position: absolute;
          content: "お友達追加";
          display: block;
          padding: 5px 0;
          text-align: center;
          border-radius: 2.6em;
          background-color: #06c755;
          position: absolute;
          font-size: 2.5vw;
          color: #fff;
          line-height: 1.1;
          top: -3vw;
          left: 5%;
          right: 5%;
          z-index: 200;
        }
      }
    }
  }
}
.footer-sp-bottom {
  @media screen and (max-width: 767px) {
    margin-bottom: 45px;
  }
}
</style>
