<template>
  <div class="marketing-develop">
    <h2>Types of EC websites<br />we can develop</h2>
    <p class="sub-title">対応可能なECサイトの構築方法</p>
    <div class="wrap">
      <div class="circle-cover">
        <div class="circle">
          <div class="circle-in">
            <div class="in">
              <p class="title">フルスクラッチ</p>
              <p class="text">
                自由度が高く、<br>本格的なEC運営をしたい<br>企業様へ
              </p>
            </div>
          </div>
        </div>
        <div class="circle">
          <div class="circle-in">
            <div class="in">
              <p class="title">汎用システム</p>
              <p class="text">
                ec-force、shopify、<br>EC-CUBEを使用して、<br>本格的なEC運営をしたい<br>企業様へ
              </p>
            </div>
          </div>
        </div>
        <div class="circle">
          <div class="circle-in">
            <div class="in">
              <p class="title">簡易システム</p>
              <p class="text">
                BASEを使用して<br>手軽にEC運営を始めたい<br>企業様へ
              </p>
            </div>
          </div>
        </div>
    </div>
        <UiFreeConsulting :backgroundColor="`white`" :type="`ec`" class="space" />
  </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiFreeConsulting from "@/components/Ui/FreeConsulting.vue";
export default {
  name: "marketing-develop",
  components: {
    UiFreeConsulting,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.back-main,
.back-sub {
  margin-bottom: 20px;
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;

    p {
      color: white;
      font-size: 36px;
    }
  }
}
.back-main {
  background-color: var(--main);
}
.back-sub {
  background-color: var(--sub);
}
.space {
  margin-top: 100px;
  @media screen and (max-width: 767px) {
margin-top: 50px;
  }
}
.circle-cover {
  display: flex;
  align-items: center;
  justify-content: space-between;
          @media screen and(max-width: 767px){
  flex-direction: column;

  }

  .circle {
    width: 32%;
    position: relative;
    @media screen and(max-width: 767px){
      width: 100%;
    }
    .circle-in {
      width: 100%;
      padding-top: 100%;
      border-radius: 50%;
      background-color: var(--main);
      @media screen and(max-width: 767px){
        border-radius: 15px;
        padding: 20px;
        margin-bottom: 20px;
      }
      .in {
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 60%;
        width:100%;
        @media screen and(max-width: 767px){
          position: static;
          height: auto;
          transform: initial;
        }
        
        .title {
          font-size: 26px;
          flex-basis: 20%;
          @media screen and(max-width: 767px){
            margin-bottom: 10px;
          }
        }
        .text{
          flex-basis: 60%;
          font-size: 20px;
          text-align: center;
          line-height: 1.8em;
          @media screen and (min-width:768px) and ( max-width:1024px){
            font-size: 16px;
          }
          // min-height: 150px;
        }
      }
    }
  }
}
</style>
