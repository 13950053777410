<template>
  <div class="recruit-top">
    <UiTop
      :imgPath="'landingpage/syukyaku-landing-001.jpg'"
      :imgPathSp="'landingpage/syukyaku-landing-001sp.jpg'"
      :en="'Develop landing<br>pages that sell'"
      :ja="'売れるランディングページ制作'"
      :imgPathMain="'landingpage/syukyaku-landing-002.jpg'"
      :img="true"
      :text="'特定の商材の販売を強化するために、1ページ1商材のランディングページを制作します。ランディングページ上のコンテンツはもちろん、離脱防止対策や高機能フォームなどの機能の搭載によって成約率を高める提案もします。これまでアフィリエイト・販売代行で結果を出し続けてきた弊社だからこそできるランディングページ制作のノウハウがあります。'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTop from "@/components/Ui/Top.vue";
export default {
  name: "recruit-top",
  components: { UiTop },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
