<template>
  <div class="home">
    <div class="top">
      <HomeTop />
    </div>
    <div class="pt-150 mb-150">
      <HomeTopClassWebsite />
    </div>

    <div class="background-color pt-150 pb-150">
      <HomeTypesOfWebsite />
    </div>

    <div class="background-color2 pt-150 pb-150">
      <HomeOperationServices />
    </div>

    <div class="pt-150">
      <HomeAnyConcernsOrProblems />
    </div>

    <div class="pt-150 pb-150">
      <HomeReasons />
    </div>
    <div class="background-color pt-150 pb-150">
      <HomeOurAchievements />
    </div>

    <div class="background-color2 pt-150 pb-150">
      <HomeAdvantages />
    </div>

    <div class="background-color">
      <HomeSEO />
    </div>

    <div class="pt-150 pb-150">
      <HomePrice />
    </div>

    <div class="background-color pt-150 pb-150">
      <HomeQa />
    </div>

    <div class="pt-150">
      <HomeFlow />
    </div>

    <div class="pt-150 pb-150 pt-non">
      <HomeContact :type="`hp`" :consul="true"/>
    </div>

    <div class="background-color pt-150 pb-150">
      <HomeServices />
    </div>

    <!-- <div class="background-color2 pt-250 pb-250">
      <HomeUseflInfo />
    </div> -->

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeTop from "@/components/Home/Top.vue";
import HomeTopClassWebsite from "@/components/Home/TopClassWebsite.vue";
import HomeTypesOfWebsite from "@/components/Home/TypesOfWebsite.vue";
import HomeOperationServices from "@/components/Home/OperationServices.vue";
import HomeAnyConcernsOrProblems from "@/components/Home/AnyConcernsOrProblems.vue";
import HomeReasons from "@/components/Home/Reasons.vue";
import HomeOurAchievements from "@/components/Home/OurAchievements.vue";
import HomeAdvantages from "@/components/Home/Advantages.vue";
import HomeSEO from "@/components/Home/SEO.vue";
import HomePrice from "@/components/Home/Price.vue";
import HomeQa from "@/components/Home/Qa.vue";
import HomeFlow from "@/components/Home/Flow.vue";
import HomeContact from "@/components/Home/Contact.vue";
import HomeServices from "@/components/Home/Services.vue";
// import HomeUseflInfo from "@/components/Home/UseflInfo.vue";

export default {
  name: "Home",
  components: {
    HomeTop,
    HomeTopClassWebsite,
    HomeTypesOfWebsite,
    HomeOperationServices,
    HomeAnyConcernsOrProblems,
    HomeReasons,
    HomeOurAchievements,
    HomeAdvantages,
    HomeSEO,
    HomePrice,
    HomeQa,
    HomeFlow,
    HomeContact,
    HomeServices,
    // HomeUseflInfo,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.background-color {
  background-color: var(--sub3);
}
.background-color2 {
  background-color: var(--sub);
}
</style>
