<template>
  <div class="mall-price wrap">
    <h2>Price</h2>
    <p class="sub-title">制作料金</p>

        <p class="mass">
        制作料金は、制作する内容・仕様によって異なります。基本的には、制作工数＝料金となるため、手のこった内容を希望される場合は、それが料金に反映されます。また、料金については、弊社にて、ホームページやECサイトなどの別案件をご依頼いただき、すでに弊社にてデザインのベースが完成している場合と、全くの新規でデザインから作る場合とでも、料金が異なります。他媒体のデザインを流用することで、制作工数を下げることが可能なため、すでに弊社に別案件を依頼し、デザインが完成しているクライアントの方が料金が安くなります。同様の理由から、他社でホームページを制作した場合でも、そのデザインデータをいただくことができ、そのデザインデータの使用を認められているのであれば、制作工数を下げることができます。</p>
        <p>印刷料金については、印刷に要する時間に応じて料金が変動します。短納期での印刷が必要な場合は、印刷業者での特急対応となるため、料金が高くなります。印刷に余裕のあるスケジュール（具体的にはデザインの完成から10日以上）においては、印刷料金を抑えることができます。</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default{
  name: "mall-price",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title{
  font-size: 20px;
  color: var(--main);
  margin: 50px 0 10px 0;
}
.note{
  text-align: right;
}
</style>
