/**
 * jsバリデーションチェック用ファイル
 * 
 * 1.チェックしたいinputタグを inputs に追加する
 * 2.mountedでcreateValidateEvent()を呼び出す
 * 3.submit時にcheckAll()を呼び出す
 */

// エラーのスタイルを指定
const styles = {
    color: "#FF0000",
    fontSize: "0.8rem",
    paddingLeft: "15px",
    border: "1px solid #FF0000"
}

// class名とチェックしたいルールを記載する
// array['class名', ['ルール1','ルール2',,,,,]]
const inputs = [
    ['cv-name', ['required', 'multibyte']],
    // ['cv-katakana', ['required', 'katakana']],
    // ['cv-tel', ['required', 'numeric']],
    ['cv-email', ['required', 'email', 'hotmail-outlook']],
    // ['cv-type', ['required']],
    // ['cv-check1', ['required']],
    ['cv-description', ['required']],
    ['cv-company', ['required']]
];

/**
 * ルール一覧
 * 
 * required : 必須入力
 * multibyte : 全角
 * katakana : 全角カタカナ
 * numeric : 数字のみ
 * email : メールアドレス（記号や@のチェック）
 * hotmail-outlook : hotmail.com、outlook.comの禁止
 * 
 */


/**
 * グローバル変数
 */
let defaultBorderStyle = null; // inputのボーダー初期値を保持する


// 入力フォーム毎にチェックを行う
/**
 * inputタグのフォーカスが外れた際のイベントを設置
 */
function createValidateEvent() {

    inputs.forEach(input => {

        // 対象のinputタグを取得
        const elm = document.querySelector("." + input[0]);

        // 要素が見つからない場合は終了
        if (!elm) {
            return;
        }

        // inputタグのdefaultスタイルを取得していない場合は取得
        if (defaultBorderStyle === null) {
            defaultBorderStyle = elm.style.border;
        }

        // タグの種類を判定する
        const tagName = elm.tagName;
        let eventName = 'blur';
        switch(tagName) {
            case "SELECT":
                eventName = 'change';
                break;
            default:
                break;
        }

        // inputタイプを判定する
        const typeName = elm.type;
        if (typeName === 'checkbox') {
            eventName = 'change';
            const checkboxList = document.querySelectorAll("." + input[0]);

            checkboxList.forEach(checkbox => {
                // イベントを設置
                checkbox.addEventListener(eventName, () => {

                    // 設置済みのエラーがあれば除去する
                    let errorMsg = document.querySelector(".cv-errorMsg-" + input[0]);
                    if (errorMsg) {
                        errorMsg.remove();
                    }

                    // inputタグのborderを初期値に戻す
                    elm.parentNode.parentNode.style.border = defaultBorderStyle;

                    // バリデーション処理
                    validate(input[0], input[1]);
                });
            });

            return;
        }

        // イベントを設置
        elm.addEventListener(eventName, () => {

            // 設置済みのエラーがあれば除去する
            let errorMsg = document.querySelector(".cv-errorMsg-" + input[0]);
            if (errorMsg) {
                errorMsg.remove();
            }

            // inputタグのborderを初期値に戻す
            elm.style.border = defaultBorderStyle;

            // バリデーション処理
            validate(input[0], input[1]);
        });
    });
}

// 指定したルールでバリデーションを行う
function validate(className, validations) {

    let classElm = document.getElementsByClassName(className)[0];

    // 要素が見つからない場合は終了
    if (!classElm) {
        return;
    }

    // 要素が非表示の場合は終了
    if (classElm.style.display === "none" || classElm.offsetHeight === 0) {
        return;
    }
    
    let value = classElm.value;
    const elmType = classElm.type;
    let errorFlg = false;
    let errorMsg = [];

    validations.forEach(validation => {
        switch(validation) {
            case "required":
                value = value.replaceAll(' |　', "");

                // checkboxの処理
                if (elmType === 'checkbox') {
                    const checkboxList = document.querySelectorAll("." + className);
                    
                    errorFlg = true;
                    checkboxList.forEach(checkbox => {
                        if (checkbox.checked) {
                            errorFlg = false;
                            return;
                        }
                    });
                    if (errorFlg) {
                        errorMsg.push("必須入力です");
                    }
                    return;
                }

                if(!value) {
                    errorFlg = true;
                    errorMsg.push("必須入力です");
                }
                break;
            case "multibyte":{
                /* eslint no-control-regex: off */
                const regex = /^[\x01-\x7E]+$/;
                if (value.match(regex)) {
                    errorFlg = true;
                    errorMsg.push("全角で入力してください");
                }
                break;}
            case "katakana":
                value = hankanaToZenkana(value);
                classElm.value = value;
                if (!value.match(/^[ァ-ヶー\x20\u3000]+$/)) { 
                    errorFlg = true;
                    errorMsg.push("全角カタカナで入力してください");
                }
                break;
            case "numeric":
                value = value.replaceAll(/(-|\u30fc)/g, ""); // ハイフン除去
                value = value.replace(/[０-９]/g, m=>'０１２３４５６７８９'.indexOf(m)); // 全角数字を半角に変換
                classElm.value = value;
                if(!value.match(/^[0-9]*$/)) {
                    errorFlg = true;
                    errorMsg.push("半角数字で入力してください");
                }
                break;
            case "email":
                if (!value.match(/^[a-z\d][\w.+-]*@[\w.-]+\.[a-z\d]+$/i)) {
                    errorFlg = true;
                    errorMsg.push("正しいメールアドレスを入力してください");
                }
                break;
            case "hotmail-outlook":
                if (value.match(/hotmail.com|outlook.com$/i)) {
                    errorFlg = true;
                    errorMsg.push("hotmail. outlookは使えません");
                }
                break;
            default:
                break;
        }
    });

    if (errorFlg) {
        createErrorMsg(classElm, errorMsg, className);
        result = false;
    }

    return errorFlg;
}
  
/**
 * エラーメッセージの生成・表示処理
 * @param {element} element 
 * @param {string} msg 
 * @param {string} className 
 */
function createErrorMsg(element, msg, className) {
    let errorMsg = document.createElement('p');
    errorMsg.className = "cv-errorMsg-" + className;
    errorMsg.innerHTML = msg[0];

    errorMsg.style.color = styles.color;
    errorMsg.style.fontSize = styles.fontSize;
    errorMsg.style.paddingLeft = styles.paddingLeft;
    
    switch(element.type) {
        case "checkbox":
            element.parentNode.parentNode.after(errorMsg);
            element.parentNode.parentNode.style.border = styles.border;
            break;
        default:
            element.parentNode.after(errorMsg);
            element.style.border = styles.border;
            break;
    }
}

/**
 * 半角カタカナを全角カタカナへ変換する
 * @param  {string} str
 * @return {string} str
 */
function hankanaToZenkana(str) {
    var kanaMap = {
        'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
        'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
        'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
        'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
        'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
        'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
        'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
        'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
        'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
        'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
        'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
        'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
        'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
        'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
        'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
        'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
        'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
        'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
        '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
    };

    var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
    return str
        .replace(reg, function (match) {
            return kanaMap[match];
        })
        .replace(/ﾞ/g, '゛')
        .replace(/ﾟ/g, '゜');
}

/**
 * 一括チェック
 */
let result;
function checkAll() {

    result = true;

    // エラー表示を全て除去する
    removeErrorMsg();

    inputs.forEach(input => {
        validate(input[0], input[1]);
    });

    return result;
}


/**
 * 全てのエラーメッセージを一括で除去する
 */
function removeErrorMsg() {

    const inputElms = document.querySelectorAll('input');
    inputElms.forEach(elm => {
        elm.style.border = defaultBorderStyle;
    });

    const errorMsgElms = document.querySelectorAll(`[class^='cv-errorMsg']`);
    errorMsgElms.forEach(elm => {
        elm.remove();
    });
}


// 関数のエクスポート
export default {
    createValidateEvent,
    checkAll
};