<template>
  <div class="recruit-top">
    <UiTop
      :imgPath="'ec/syukyaku-EC-websit-001.jpg'"
      :imgPathSp="'ec/syukyaku-EC-websit-001sp.jpg'"
      :en="'Develop<br>EC websites'"
      :ja="'ECサイト・ネットショップ制作'"
      :imgPathMain="'ec/syukyaku-EC-websit-003.jpg'"
      :img="true"
      :text="'岐阜市にある株式会社グレイトヘルプは、マーケティング・集客・販売に強いECサイトの制作を得意としています。フルスクラッチでの構築や汎用システムを用いた構築など、クライアントの案件・目的に合わせた構築方法を提案します。ECサイトで有効となる施策も提案でき、また、構築後の集客業務も請け負うことができます。'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTop from "@/components/Ui/Top.vue";
export default {
  name: "recruit-top",
  components: { UiTop },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
