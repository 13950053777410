<template>
  <div class="home">
    <div class="top mb-150">
      <MarketingTop />
    </div>

    <div class="pb-150 background-color pb-non">
      <MarketingDevelop />
    </div>

    <div class="pt-150 pb-150 background-color2">
      <MarketingOffer />
    </div>

    <div class="pt-150 pb-150 background-color">
      <MarketingSell />
    </div>

    <div class="pt-150 pb-150">
      <HomeReasons />
    </div>
    <div class="background-color pt-150 pb-150">
      <HomeOurAchievements />
    </div>

    <div class="background-color2 pt-150 pb-150 pb-non">
      <HomeAdvantages />
    </div>
    <div
      class="parallax_content1"
      v-prlx.mobile.background="{
        direction: 'y',
        fromBottom: true,
        speed: 0.1,
      }"
    ></div>

    <div class="pt-150 pb-150">
      <MarketingPrice />
    </div>

    <div class="background-color pt-150 pb-150">
      <MarketingQa />
    </div>

    <div class="pt-150 pb-150 pb-non">
      <HomeFlow />
    </div>

    <div class="pt-150 pb-150 pt-non">
      <HomeContact :type="`hp`" :consul="true"/>
    </div>

    <div class="background-color pt-150 pb-150 pb-non">
      <HomeServices />
    </div>

    <!-- <div class="background-color2 pt-250 pb-250">
      <HomeUseflInfo />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MarketingTop from "@/components/Marketing/Top.vue";
import MarketingDevelop from "@/components/Marketing/Develop.vue";
import MarketingOffer from "@/components/Marketing/Offer.vue";
import MarketingSell from "@/components/Marketing/Sell.vue";
import HomeReasons from "@/components/Home/Reasons.vue";
import HomeOurAchievements from "@/components/Home/OurAchievements.vue";
import HomeAdvantages from "@/components/Home/Advantages.vue";
import MarketingPrice from "@/components/Marketing/Price.vue";
import MarketingQa from "@/components/Marketing/Qa.vue";
import HomeFlow from "@/components/Home/Flow.vue";
import HomeContact from "@/components/Home/Contact.vue";
import HomeServices from "@/components/Home/Services.vue";
// import HomeUseflInfo from "@/components/Home/UseflInfo.vue";

export default {
  name: "Home",
  components: {
    MarketingTop,
    MarketingDevelop,
    MarketingOffer,
    MarketingSell,
    HomeReasons,
    HomeOurAchievements,
    HomeAdvantages,
    MarketingPrice,
    MarketingQa,
    HomeFlow,
    HomeContact,
    HomeServices,
    // HomeUseflInfo,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.background-color {
  background-color: var(--sub3);
}
.background-color2 {
  background-color: var(--sub);
}
.parallax_content1 {
  padding: 250px 0;
  background-image: url(~@/assets/marketing/syukyaku-syukyaku-back002.jpg);
  background-size: cover;
          @media screen and (min-width:768px) and ( max-width:1024px){
    padding: 150px;
    }
    @media screen and(max-width: 767px){
    background-size: auto 100vw;
    padding: 120px 0;
  }
}
</style>
