<template>
  <div class="mall-price wrap">
    <h2>Price</h2>
    <p class="sub-title">制作料金</p>
        <p>
        制作料金は、開発するシステムの内容によって異なります。基本的には、制作工数＝料金となるため、機能が多い、処理する情報が多い、システムの仕様が複雑など、手間がかかるほど料金は比例して高くなります。弊社実績として、小規模なシステムであれば、55万(税込)〜という案件もあります。システム化によって、人員削減や業務効率アップを実現できるため、投資による効果は必ず得られます。
        </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default{
  name: "mall-price",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title{
  font-size: 20px;
  color: var(--main);
  margin: 50px 0 10px 0;
}
.note{
  text-align: right;
}
</style>
