<template>
  <div class="mall-price wrap">
    <h2>Price</h2>
    <p class="sub-title">制作料金</p>

        <p>
        制作料金は、制作するショッピングモールの内容によって異なります。基本的には、制作工数＝料金となるため、商品カテゴリ数・商品点数が多い、制作するコンテンツ量、調査・マーケティングに要する工数など、手間がかかるほど料金は比例して高くなります。いずれにしても、「クライアントのご指定の予算内で提案する」というスタンスのため、予算オーバーになることはございません。ECサイトを作るとは、ネット上に自分のお店を持つことです。収益のために、自分のお店に投資をすることは当然のことであり、それは必ず結果となって返ってきます。
        </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default{
  name: "mall-price",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title{
  font-size: 20px;
  color: var(--main);
  margin: 50px 0 10px 0;
}
.note{
  text-align: right;
}
</style>
