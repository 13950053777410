<template>
  <div class="home-contact">
    <div class="wrap">
      <h2>Contact</h2>
      <p class="sub-title">お問い合わせ</p>
      <img alt="花と蝶" src="@/assets/syukyaku-top-ira007.png" class="hana" />
      <UiFreeConsulting
        :backgroundColor="`lightblue`"
        class="consul"
        :type="type"
        v-show="consul === true"
      />
    </div>
    <div class="wrap">
      <div class="white-title" id="mailto"><p>メールでのお問い合わせ</p></div>
      <!-- <div class="guide">
        <p>以下のフォームに必要事項を記入して、送信をお願いします。</p>
      </div> -->
      <HomeContactContactForm
        class="space-btm-200 guide"
        style="background-color: #f2f2f2"
      />

      <div class="white-title"><p>お電話でのお問い合わせ</p></div>
      <div class="guide">
        <a href="tel:0582149005"><p class="tel">058-214-9005</p></a>
        <p class="small">（受付時間:平日9時 ～ 18時）</p>
        <p class="info">お電話でのお問い合わせの際は、050番号以外の電話番号から発信をお願いいたします。<br class="pc-only">050番号からの営業電話に迷惑をしているため、050番号からの着信には応答いたしません。</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiFreeConsulting from "@/components/Ui/FreeConsulting.vue";
import HomeContactContactForm from "@/components/Home/Contact/ContactForm.vue";

export default {
  name: "HomeAboutUs",
  components: {
    UiFreeConsulting,
    HomeContactContactForm,
  },
  props: { type: String, consul: Boolean },
  data() {
    return {
      pageType: 0,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.home-contact {
  .wrap {
    position: relative;
  }
  .hana {
    position: absolute;
    right: 0;
    top: 20px;
    width: 200px;
    @media screen and(max-width: 767px) {
      width: 100px;
      right: 20px;
      top: -10px;
    }
  }
  .white-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    border: 2px solid var(--main);
    margin-bottom: 50px;
    @media screen and (max-width: 767px) {
      padding: 10px 0;
      margin-bottom: 30px;
    }
    p {
      font-size: 32px;
      color: var(--main);
      @media screen and (max-width: 767px) {
        font-size: 23px;
      }
    }
  }
  .consul {
    margin-bottom: 100px;
  }
  .guide {
    text-align: center;
    color: var(--main);
    margin-top: 20px;
    margin-bottom: 80px;
    @media screen and (max-width: 767px) {
      margin-bottom: 40px;
    }
    .head {
      @media screen and (max-width: 767px) {
        padding-bottom: 30px;
      }
    }

    a {
      text-decoration: none;
      color: var(--main) !important;
    }
    .tel {
      font-size: 60px;
      font-weight: bold;
      margin-top: 30px;
      color: var(--main) !important;
      @media screen and (max-width: 767px) {
        font-size: 45px;
        margin-top: 0px;
      }
    }
    .small {
      font-size: 22px;
      margin-top: 20px;
      @media screen and (max-width: 767px) {
        margin-top: 10px;
      }
    }

    .info {
      margin-top: 20px;
    }

    @media (min-width: 751px) {
      a[href*="tel:"] {
        pointer-events: none;
        cursor: default;
      }
    }
  }
  .bottom {
    height: 200px;
    padding-top: 100px;
    @media screen and (max-width: 767px) {
      padding-top: 50px;
    }
    .container {
      width: 100%;
      img {
        vertical-align: bottom;
        width: 100%;
      }
    }
  }
}
</style>
