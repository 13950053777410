<template>
  <div class="free-consulting">
    <div
      class="white-back"
      :class="{
        white: backgroundColor === 'white',
        lightblue: backgroundColor === 'lightblue',
      }"
    >
      <h3>初回無料相談&コンサルティングを<br class="sp-only">実施しています</h3>

      <div class="img-cover">
        <img
          alt="初回コンサルティング風景"
          class="sya"
          src="@/assets/syukyaku-top-009.jpg"
        />
        <!-- :class="{ 'over-baseline': scrollY >= 5500 }" -->
        <img
          class="ila"
          alt="初回コンサルティング風景イラスト"
          src="@/assets/ezgif.com-apng-maker-1.png"
        />
      </div>
      <p v-if="type === 'hp'">
        初めて弊社にお仕事のお問い合わせをする事業主様限定で、無料相談&コンサルティングを行っています。ホームページを新規で制作する場合は、目的をお聞きし、その上で、具体的にどのようなホームページを作るとよいかという提案をします。リニューアルの場合は、現状からの改善希望や目的などをお聞きし、ホームページの診断・改善提案をします。ホームページ制作業者として有数の実績を持つ、弊社のマーケティング担当からホームページや事業にとって必ずプラスとなるお話をさせていただきます。
      </p>
      <p v-else-if="type === 'ec'">
        初めて弊社にお仕事のお問い合わせをする事業主様限定で、無料相談&コンサルティングを行っています。ECサイトを新規で制作する場合は、商材や目的をお聞きし、その上で、具体的にどのようなECサイトを作るとよいかという提案をします。リニューアルの場合は、現状からの改善希望や目的などをお聞きし、ECサイトの診断・改善提案をします。ECサイト制作業者として有数の実績を持つ、弊社のマーケティング担当からECサイトの構築や事業にとって必ずプラスとなるお話をさせていただきます。
      </p>
      <img
        class="ila-sp"
        alt="初回コンサルティング風景イラスト"
        src="@/assets/ezgif.com-apng-maker-1.png"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiFreeConsulting",
  props: {
    // path: String,
    backgroundColor: String,
    type: String,
  },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
      // type:"hp",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.white {
  background-color: white;
}
.lightblue {
  background-color: var(--sub3);
}
.white-back {
  margin-top: 50px;
  padding: 80px;
  color: var(--main);
  @media screen and(max-width: 767px) {
  padding: 30px 20px;
  }
  h3{
    @media screen and(max-width: 767px){
      font-size: 18px;
    }
  }
  .img-cover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0;
    @media screen and (max-width: 767px) {
      margin: 30px 0;
    }
    .sya {
      width: 55%;
      @media screen and (max-width: 767px){
        width: 100%;
      }
      // clip-path: polygon(0 0, 200% 0, 0 200%);
      // clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);

      //復活させるならこの2行↓
      // transition: all 0.8s ease;
      // clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
    .ila {
      width: 38%;
      @media screen and (max-width: 767px){
        display: none;
      }
    }
    p {
      margin-top: 30px;
    }
    .over-baseline {
      // clip-path: polygon(0 0, 200% 0,200% 0, 0 200%);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
  }
      .ila-sp{
        display: none;
        @media screen and (max-width: 767px){
          width: 80%;
          display: block;
          margin: 0 auto;
        }
      }
}
</style>
