<template>
  <div class="mall-advantages">
    <h2>Advantages of our shopping mall<br />development</h2>
    <p class="sub-title">グレイトヘルプのショッピングモール制作のメリット</p>

    <div
      class="wrap-right"
      :class="{ 'over-baseline': displayData.advantage01 === true }"
    >
      <p
        class="blue-title transparent"
        :class="{ fadeIn: displayData.advantage01 === true }"
      >
        ECサイト・モールでの販売で売上アップの実績多数
      </p>
      <p
        class="text transparent"
        :class="{ fadeIn: displayData.advantage01 === true }"
      >
        上記のとおり、弊社は、ウェブ制作業者の中でも、有数の実績を保有しています。ECサイトをただ形として作るだけでなく、売上アップや認知拡大、アクセス数増など、希望・目的を実現できるショッピングモールを作るノウハウとスキルを会社として有しています。その理由は、弊社は、ネット上での完全成果報酬の販売代行事業をルーツとした企業だからです。販売成功すれば報酬をいただける、販売できなければ報酬をいただけないという条件の事業を長年運営している、本物のマーケティング力・集客力があります。
      </p>
    </div>

    <div
      class="wrap-left"
      :class="{ 'over-baseline': displayData.advantage02 === true }"
    >
      <p
        class="blue-title transparent"
        :class="{ fadeIn: displayData.advantage02 === true }"
      >
        プロダクト・現状に合わせたモール選定と出店戦略の提案
      </p>
      <p
        class="text transparent"
        :class="{ fadeIn: displayData.advantage02 === true }"
      >
        上述のとおり、弊社は戦略的に出店するモールを選定します。クライアントから「このモールをやりたい」と言われて、言われるまま対応する、というスタンスではありません。クライアントのプロダクト・現状をしっかりと考慮して、調査によって根拠とともに出店するモールを決定していきます。
      </p>
    </div>

    <div class="wrap-right">
      <p class="blue-title">マーケティングを駆使し、データに基づいた設計</p>
      <p class="text">
        弊社は、ただショッピングモールを作ることはしません。マーケティングと調査を行い、商品の設計、ページの構成、コンテンツ、設定するSEOキーワードなどを設計していきます。結果が出すために、裏付けとともにすべてを設計していきます。
      </p>
    </div>

    <div class="wrap-left">
      <p class="blue-title">モール内SEOを意識したショップ構築</p>
      <p class="text">
        ショッピングモール内の検索エンジンからのアクセス獲得は、ショッピングモールでの販売において、非常に重要です。各モールによって、SEOの特徴が異なるため、それぞれのモールのSEOを意識したショップの構築を行います。
      </p>
    </div>

    <div class="wrap-right">
      <p class="blue-title">結果が出ているショップページの構成と施策</p>
      <p class="text">
        これまでいくつもショッピングモールの制作を対応した知見・データから、結果が出るショップページの構成と施策を弊社は把握しています。これらの結果が出ているショップページの構成と施策を適用するため、結果への再現性が高いです。
      </p>
    </div>

    <div class="wrap-left">
      <p class="blue-title">まるまる仕事をお願いできる</p>
      <p class="text">
        ショッピングモールの制作業者の中には、「ショップに掲載する文章は書く技術がないから文章はクライアントから提供してください」「撮影はできないから写真は提供してください」というように、全業務を請け負えない会社もあります。弊社は、文章作成・コーディング・デザイン・写真撮影・イラスト作成など、ショッピングモール制作において必要なことは当然すべて対応できるので、貴社にて手間をかけることなく、また、依頼する業者を分割をすることもなく、すべてお任せすることができます。
      </p>
    </div>

    <div class="wrap-right">
      <p class="blue-title">柔軟なコスト調整</p>
      <p class="text">
        ショッピングモール制作において必要なことを全て弊社に依頼することもできますが、逆に、依頼内容を調整することで、コストを削減することもできます。たとえば、「文章は貴社にてご用意をいただければこれだけコストが下がります」「写真はあるものを使用することでこれだけコストが下がります」というように、貴社が保有する資源をお聞きし、弊社へ依頼する業務範囲を調整することで、コスト調整ができる仕組みになっています。
      </p>
    </div>

    <div class="wrap-left">
      <p class="blue-title">
        ショッピングモールの知識がなくても、できるようにサポート
      </p>
      <p class="text">
        ショッピングモールが気になる、ショッピングモールをやってみたいけど、どうすればいいのか詳しいことまでは分かっていないという場合でも、大丈夫です。弊社から、ショッピングモールを立ち上げ・運営するにあたって必要なことを全てお教えすることができますので、弊社のサポートのもと、ショッピングモールでの販売対する知識がなくても、立ち上げ可能です。
      </p>
    </div>

    <div class="wrap-right">
      <p class="blue-title">制作後の集客業務まで請け負うことができる</p>
      <p class="text">
        弊社は、ショッピングモールを制作するだけで、制作後は何もできない、というショッピングモール制作会社ではありません。ショッピングモールを通して売上増や認知拡大などのために、制作後の集客業務まで請け負うことができます（別途料金にて）。ただ作るだけでなく、制作後の集客や運用についてもご相談いただけます。
      </p>
    </div>

    <div class="wrap-left">
      <p class="blue-title">事業内容の価値を高める提案も可能</p>
      <p class="text">
        弊社の強みはマーケティング力です。ショッピングモールを制作するにあたってのマーケティングに基づいて、「貴社の商品はこうしたら、より販売力が高くなる」「このポジションが有利だ」ということまで導くことができます。<span class="kome">（※マーケティングまで依頼内容に含む場合）</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiBox4 from "@/components/Ui/Box4.vue";

export default {
  name: "mall-advantages",
  components: {
    // UiBox4
  },
  data() {
    return {
      documentheight: 0,

      //
      displayData: {
        advantage01: false,
        advantage02: false,
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "scrollY"]),
  },
  watch: {
    scrollY(newValue) {
      // advantage01
      if (newValue >= 15560) {
        this.displayData.advantage01 = true;
      }

      // advantage02
      if (newValue >= 16200) {
        this.displayData.advantage02 = true;
      }

      // advantage03
      //
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.wrap-left,
.wrap-right {
  background-color: var(--sub3);
  margin-bottom: 50px;
  transition: all 0.8s ease;
  // clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  @media screen and (max-width: 767px) {
    margin-bottom: 5px;
  }

  .blue-title {
    color: var(--main);
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: 500;
    @media screen and (max-width: 767px) {
      font-size: 23px;
      line-height: 1.5em;
    }
  }
  .text {
    color: var(--black);
  }
  // .transparent {
  //   opacity: 0;
  // }
  .fadeIn {
    transition: opacity 2s 0.8s;
    opacity: 1;
  }
  /deep/ul {
    margin: 30px 0;
  }
  @media screen and (max-width: 767px) {
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.over-baseline {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important;
}

.wrap-left {
  margin-left: calc(calc(100vw - 1100px) * 0.5);
  padding: 50px calc(calc(calc(100vw - 1150px) * 0.5) + 105px) 50px 80px;
  @media screen and(max-width: 1024px) {
    margin-left: 0;
    padding: 40px 20px;
  }
}
.wrap-right {
  margin-right: calc(calc(100vw - 1100px) * 0.5);
  padding: 50px 100px 50px calc(calc(calc(100vw - 1150px) * 0.5) + 105px);
  @media screen and(max-width: 1024px) {
    margin-right: 0;
    padding: 40px 20px;
  }
}
.wrap-left:last-child{
        @media screen and (min-width:768px) and ( max-width:1024px){
      margin-bottom: 0;
      }
}
/deep/h2,
.sub-title,
p {
  color: white;
}

/deep/.link-button {
  a {
    color: white;
    border: white 1px solid;
    &:hover {
      background-color: white;
      color: var(--sub);
    }
  }
}

.over-baseline {
  clip-path: polygon(0 0, 200% 0, 0 200%);
}
</style>
