<template>
  <div
    class="home-seo parallax_content1"
    v-prlx.mobile.background="{
      direction: 'y',
      fromBottom: true,
      speed: 0.025,
    }"
  >
    <div class="wrap">
      <h2>Our thoughts<br />on SEO strategy</h2>
      <p class="sub-title">SEO対策に関する弊社の考え</p>
      <div class="min-white-back">
        <p class="title">
          Googleが向かっている方向にミートした、<br class="pc-only"/>
          リスクのない本質的なSEOによって、<br class="pc-only"/>
          中長期的に検索エンジンからの集客を目指す。
        </p>
        <p class="text">
          弊社は創業の2012年からSEO（特定の検索ワードでの検索時に、検索結果の上位にホームページを表示すること）に携わっていますが、ものすごいスピードでSEOは進化をしています。かつては、狙いたいキーワードの日本語ドメインでホームページを作ればすぐに上位表示でき、海外のドメインオークションでページランクの高い中古ドメインを取得し、そのドメインからホームページにリンクをすれば上がった、というような時代でした。中古ドメインを取得するノウハウを持つ弊社にとっては、まさに楽勝で、「Googleが下げたいサイトでも外部リンクの力で上げてしまうことがSEOだ」と考えるほどでした。
        </p>
        <p class="text">
          しかし、現在の検索エンジンは、実際のホームページの品質に関係のないことに対する評価をほぼしなくなっています。現在の検索エンジンは、ホームページのコンテンツ力と、そのホームページの企業や商材自体の価値を評価しています。どれほどコンテンツを凝って作っても、コンテンツのもととなるそのサービス内容自体が、閲覧者にとって価値のないものや競争優位性のないものであれば、価値のあるサービスを提供しているホームページをSEOで抜くことは難しいということです。また、特定のホームページが特定のキーワードでずっと１位にいる、という状態もなくなりつつあります。
        </p>
        <p class="text">
          そのため、弊社がSEOで大切にしていることは、狙うべき検索キーワードのマーケティングと、そのキーワードで検索エンジンに評価をしてもらうためのコンテンツ作りです。また、そのマーケティングの過程で導き出した市場のニーズを提案し、貴社サービスへ取り入れてもいただくことで、事業としての価値を高め、それによって検索エンジンからの評価も高めつつ、対閲覧者の反応も上げることを目指します。そして、立ち上げ後のサイト解析によって改善案を導き出し、その改善案を実行し、様子を見て、次の改善案を打つという方法で運用する、とても現実的かつ慎重な方法で、現在のGoogleが求めているSEOをホームページに施すことをスタンスとしています。
        </p>
        <p class="text">
          「どんなキーワードも上位表示できます」とか「１日◯円で上位表示します」ということを謳ったサービスもあります。しかし、SEOを武器にしたウェブのマーケティングに長年携わり、常時400サイトを運営・検証し、そのノウハウをセミナーを通して同業者にも提供する立場にある、現在のSEOの現実を知っている弊社は、絶対にそのような安易なことは言えません。
        </p>
        <p class="text">
          ホームページは会社の顔です。そのホームページに対して、どのようなSEOの考えを適用するか、これによりホームページの寿命もパフォーマンスも決定してしまいます。間違ったSEO対策をすることはもちろん、どのようなSEO対策をするのか不明な場合は、とても危険です。間違ったSEO対策で、企業のホームページがGoogleからペナルティを受けて、検索結果に表示されない状態で、弊社に仕事の相談がきたこともあります。だからこそ、安易で雑で根拠のないSEOは絶対にしてはいけないと考えています。今の時代に必要なことは、リスクのない本質的なSEOです。<br><span class="kome">※ホームページ制作後のSEO対策は別途料金にて承っています。</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "home-seo",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.parallax_content1 {
  padding: 250px 0;
  background-image: url(~@/assets/syukyaku-top-back001.jpg);
  width: 100%;
  // height: 400px;
  background-size: cover;
  @media screen and (max-width: 767px) {
    padding: 120px 0;
    background-image: url(~@/assets/syukyaku-top-back001sp.jpg);
    background-size: initial;
    // background-size: auto 100vw;
    background-repeat: no-repeat;
  }
}
.min-white-back {
  background-color: white;
  padding: 130px 108px;
  @media screen and (max-width: 767px) {
    padding: 30px 20px;
  }
  .title {
    color: var(--main);
    font-size: 35px;
    line-height: 1.8em;
    margin-bottom: 50px;
    @media screen and(max-width: 767px) {
      font-size: 23px;
      margin-bottom: 30px;
    }
  }
  .text {
    margin-bottom: 30px;
  }
}
</style>
